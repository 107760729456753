import { useState } from "react";
import { useAuth } from "../../firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { format, set } from "date-fns";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Table from "@mui/joy/Table";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Drawer from "@mui/joy/Drawer";
import DialogTitle from "@mui/joy/DialogTitle";
import ModalClose from "@mui/joy/ModalClose";
import Textarea from "@mui/joy/Textarea";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Avatar from "@mui/joy/Avatar";

import Add from "@mui/icons-material/Add";
import QuestionMark from "@mui/icons-material/QuestionMark";
import { useCustomer } from "./CustomerProfile";
import { useSnackbar } from "../../utils/SnackbarContext";

const NoteSchema = Yup.object().shape({
    note: Yup.string().required("Note is required")
});

export default function CustomerProfileNotes() {

    const { authUser } = useAuth();
    const customer = useCustomer();
    const showSnackbar = useSnackbar();

    let notes = [];
    const [showModal, setShowModal] = useState(false);

    if (customer && customer.notes) {
        notes = customer.notes;
        notes = notes.sort((a, b) => b.date - a.date);
    }

    const handleSubmit = (values, { setSubmitting, resetForm }) => {
        const newNote = {
            note: values.note,
            date: set(new Date(), { seconds: 0 }),
            createdBy: authUser.uid,
            createdByName: authUser.displayName
        };
        const customerRef = doc(db, "companies", authUser.company.uid, "customers", customer.uid);
        const newNotes = [...notes, newNote];
        setDoc(customerRef, { notes: newNotes }, { merge: true })
            .then(() => {
                setShowModal(false);
                showSnackbar("Note created successfully", "success");
                resetForm();
            })
            .catch((error) => {
                showSnackbar("An error occurred: " + error, "danger");
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const columns = ["Date", "Note", "Created By"];
    const rowRenderer = (note) => {
        return (
            <tr key={note.uid}>
                <td>{format(note.date.toDate(), "dd/MM/yyyy' 'HH:mm")}</td>
                <td>{note.note}</td>
                <td>{note.createdByName}</td>
            </tr>
        );
    };

    if (!customer) {
        return null;
    }
    return (
        <Stack direction="column" spacing={2}>
            <Stack direction="row" justifyContent="space-between">
                <Typography level="h4">Notes</Typography>
                <Button size="sm" onClick={() => setShowModal(true)} variant="solid" color="primary"
                    startDecorator={<Add />}
                >
                    Create Note
                </Button>
            </Stack>
            <Card variant="outlined">
                <CardContent>
                    <Sheet sx={{ background: "transparent" }}>
                        <Table
                            sx={{
                                '--TableCell-headBackground': 'transparent',
                                '--Table-headerUnderlineThickness': '1px',
                                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                '--TableCell-paddingY': '4px',
                                '--TableCell-paddingX': '8px',
                            }}
                        >
                            <thead>
                                <tr>
                                    {columns.map((column) => (
                                        <th key={column} style={{ padding: '12px 6px', fontSize: "0.9em" }}>{column}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {customer && notes && notes.map(rowRenderer)}
                                {customer && notes && notes.length === 0 &&
                                    <tr>
                                        <td colSpan={columns.length}>
                                            <Stack direction="column" alignItems="center" p={4}>
                                                <Avatar size="sm" variant="soft" color="neutral">
                                                    <QuestionMark fontSize="xl" />
                                                </Avatar>
                                                <Typography level="body-xs">
                                                    No notes found
                                                </Typography>
                                            </Stack>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Sheet>
                </CardContent>
            </Card>
            <Drawer anchor="right" open={showModal} onClose={() => setShowModal(false)}>
                <DialogTitle>Create Note</DialogTitle>
                <ModalClose />
                <Stack p={2} spacing={2}>
                    <Formik
                        initialValues={{ note: "" }}
                        validationSchema={NoteSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <Stack direction="column" spacing={1}>
                                    <FormControl>
                                        <FormLabel>Message</FormLabel>
                                        <Field name="note" as={Textarea} minRows={3} />
                                    </FormControl>
                                    <FormControl>
                                        <Button
                                            size="sm"
                                            variant="solid"
                                            color="primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                            loading={isSubmitting}
                                        >
                                            Save
                                        </Button>
                                    </FormControl>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                </Stack>
            </Drawer>
        </Stack>
    );

}
