import Box from "@mui/joy/Box";
import { Link } from "react-router-dom";
import { ReactComponent as TrueKYCLogo } from './../assets/logo.svg';

export default function Logo() {
  return(
    <Box>
      <Link to="/">
        <TrueKYCLogo height={20}/>
      </Link>
    </Box>
  );
}