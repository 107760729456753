import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../../firebase/auth';
import { auth } from '../../firebase/firebase';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword, updateProfile } from 'firebase/auth';
import { useSnackbar } from '../../utils/SnackbarContext';
import { useNavigate } from 'react-router-dom';

import PasswordRequirements from '../../components/PasswordRequirements';

import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';

export default function Account() {
    const { authUser } = useAuth();
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();

    // Define Yup validation schema for display name
    const validationSchema = Yup.object({
        displayName: Yup.string()
            .required('Name is required')
            .min(2, 'Name must be at least 2 characters'),
    });

    // Initialize Formik for profile updates
    const formikProfile = useFormik({
        initialValues: {
            displayName: authUser.displayName || '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await updateProfile(auth.currentUser, { displayName: values.displayName });
                showSnackbar("Profile updated", "success");
                navigate('/settings/account', { replace: true });
            } catch (error) {
                showSnackbar("An error occurred: " + error.message, "danger");
            } finally {
                setSubmitting(false);
            }
        },
    });

    // Initialize Formik for password changes
    const formikPassword = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().when('newPassword', {
                is: (val) => val && val.length > 0,
                then: (schema) => schema.required('Old password is required'),
            }),
            newPassword: Yup.string()
                .min(8, 'Password must be at least 8 characters long')
                .max(20, 'Password cannot be longer than 20 characters')
                .matches(/[A-Z]/, 'Password must have at least one uppercase letter')
                .matches(/[a-z]/, 'Password must have at least one lowercase letter')
                .matches(/[0-9]/, 'Password must have at least one number'),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const credential = EmailAuthProvider.credential(auth.currentUser.email, values.oldPassword);
                await reauthenticateWithCredential(auth.currentUser, credential);
                await updatePassword(auth.currentUser, values.newPassword);
                showSnackbar("Password updated successfully", "success");
            } catch (error) {
                showSnackbar("Error updating password: " + error.message, "danger");
            } finally {
                setSubmitting(false);
            }
        },
    });

    return (
        <Stack direction="column" spacing={2}>
            {/* Profile Update Form */}
            <form onSubmit={formikProfile.handleSubmit}>
                <Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography level="title-md">Personal Information</Typography>
                    </Box>
                    <Stack direction="column" spacing={1}>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input disabled type="email" value={authUser.email} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Phone</FormLabel>
                            <Input disabled type="text" value={authUser.phoneNumber || ''} />
                        </FormControl>
                        <FormControl error={formikProfile.touched.displayName && !!formikProfile.errors.displayName}>
                            <FormLabel>Name</FormLabel>
                            <Input
                                type="text"
                                name="displayName"
                                value={formikProfile.values.displayName}
                                onChange={formikProfile.handleChange}
                                onBlur={formikProfile.handleBlur}
                            />
                            {formikProfile.touched.displayName && formikProfile.errors.displayName && (
                                <FormHelperText>{formikProfile.errors.displayName}</FormHelperText>
                            )}
                        </FormControl>
                    </Stack>
                </Box>
                <Button
                    size="sm"
                    type="submit"
                    loading={formikProfile.isSubmitting}
                    disabled={formikProfile.isSubmitting}
                    sx={{ mt: 2 }}
                >
                    Save Profile
                </Button>
            </form>

            {/* Password Change Form */}
            <form onSubmit={formikPassword.handleSubmit}>
                <Box sx={{ mt: 2 }}>
                    <Typography level="title-md">Change Password</Typography>
                    <Stack direction="column" spacing={1} mt={2}>
                        <FormControl error={formikPassword.touched.oldPassword && !!formikPassword.errors.oldPassword}>
                            <FormLabel>Old Password</FormLabel>
                            <Input
                                type="password"
                                name="oldPassword"
                                value={formikPassword.values.oldPassword}
                                onChange={formikPassword.handleChange}
                                onBlur={formikPassword.handleBlur}
                            />
                            {formikPassword.touched.oldPassword && formikPassword.errors.oldPassword && (
                                <FormHelperText>{formikPassword.errors.oldPassword}</FormHelperText>
                            )}
                        </FormControl>
                        <FormControl error={formikPassword.touched.newPassword && !!formikPassword.errors.newPassword}>
                            <FormLabel>New Password</FormLabel>
                            <Input
                                type="password"
                                name="newPassword"
                                value={formikPassword.values.newPassword}
                                onChange={formikPassword.handleChange}
                                onBlur={formikPassword.handleBlur}
                            />
                            {formikPassword.touched.newPassword && formikPassword.errors.newPassword && (
                                <FormHelperText>{formikPassword.errors.newPassword}</FormHelperText>
                            )}
                        </FormControl>
                        <PasswordRequirements password={formikPassword.values.newPassword} />
                    </Stack>
                </Box>
                <Button
                    size="sm"
                    type="submit"
                    loading={formikPassword.isSubmitting}
                    disabled={formikPassword.isSubmitting}
                    sx={{ mt: 2 }}
                >
                    Change Password
                </Button>
            </form>
        </Stack>
    );
}
