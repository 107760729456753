import { useCompany } from "../firebase/auth";
import { Navigate, Outlet } from "react-router-dom";

function SubRoute() {
    const { authCompany } = useCompany();

    if (authCompany?.subscription?.status === "active" || authCompany?.subscription?.status === "trialing") {
        return <Outlet />;
    }
    else{
        return <Navigate to="/subscribe" />;
    }
}

export default SubRoute;
