import Page from '../../layout/Page';
import { useAuth, useCompany } from '../../firebase/auth';
import { Timestamp, addDoc, collection, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import { format } from 'date-fns';
import StatusTag from '../../components/StatusTag';
import { useState } from 'react';
import LevelSelectForm from '../../forms/levels/LevelSelectForm';
import QRLink from '../../components/QRLink';
import RiskTag from '../../components/RiskTag';
import BusinessFilterForm from '../../forms/businesses/BusinessFilterForm';
import BusinessCreateForm from '../../forms/businesses/BusinessCreateForm';
import Header from '../../layout/Header';

import DialogTitle from '@mui/joy/DialogTitle';
import ModalClose from '@mui/joy/ModalClose';
import Button from '@mui/joy/Button';
import Grid from '@mui/joy/Grid';
import Chip from '@mui/joy/Chip';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
import Drawer from '@mui/joy/Drawer';
import Stack from '@mui/joy/Stack';

import Add from '@mui/icons-material/Add';
import { useSnackbar } from '../../utils/SnackbarContext';

export default function Businesses() {

    const { authUser } = useAuth();
    const { authCompany } = useCompany();
    const showSnackbar = useSnackbar();

    const [showModal, setShowModal] = useState(false);
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [showCreateDrawer, setShowCreateDrawer] = useState(false);
    const [link, setLink] = useState(null);
    const [linkLoading, setLinkLoading] = useState(false);
    const [levels, setLevels] = useState(null);
    const [filters, setFilters] = useState({
        status: [],
        level: [],
        risk: [],
        country: [],
        date: {
            start: '',
            end: ''
        }
    });
    const [filterConstraints, setFilterConstraints] = useState([]);
    const [refresh, setRefresh] = useState(false);

    const navigate = useNavigate();

    const navigateToBusinessDetails = (businessId) => {
        navigate("./"+businessId, { relative: "path"});
    }

    const handleClose = () => {
        setShowModal(false);
        setLink(null);
        setShowCreateDrawer(false);
        setSelectedLevel(null);  // Clear selected level when closing the drawer
    }
    
    const handleShow = () => {
        setSelectedLevel(null);  // Clear selected level when showing the modal
        setShowModal(true);
    };

    const handleLevelSelect = (level) => {
        setSelectedLevel(level);
    }

    const handleCreateLink = () => {
        const link = {
            level: {
                id: selectedLevel.id,
                name: selectedLevel.name,
                documents: selectedLevel.documents,
                fields: selectedLevel.fields,
                verifications: selectedLevel.verifications,
                type: selectedLevel.type
            },
            type: selectedLevel.type,
            company: authUser.company.uid,
            companyName: authCompany.name,
            expirationDate: new Date(Timestamp.now().seconds * 1000 + 86400000),
            createdBy: authUser.uid,
            createdByName: authUser.displayName
        }

        if (authCompany?.subscription?.limits?.kyb <= 0) {
            showSnackbar("You have reached the limit of KYB verifications allowed in your plan", "danger");
            return;
        }
        
        const linkRef = collection(db, 'links');

        setLinkLoading(true);
        addDoc(linkRef, link)
        .then((docRef) => {
            setLink(window.location.origin + "/link/kyb/" + docRef.id);
        })
        .catch((error) => {
            showSnackbar("An error occurred: " + error, "danger");
        })
        .finally(() => {
            setLinkLoading(false);
        });
    }

    const headerButtons = () => {
        return (
            <Button
                size="sm"
                variant="solid"
                color="primary"
                startDecorator={<Add />}
                onClick={handleShow}
            >
                Create New
            </Button>
        );
    }

    const onComplete = () => {
        setShowCreateDrawer(false);
        setShowModal(false);
        setSelectedLevel(null);  // Clear selected level after completing an action
        setRefresh(prev => !prev);
    }

    const dataQuery = query(collection(db, 'companies', authUser.company.uid, 'businesses'), orderBy("updatedAt", "desc"));
    const searchField = "keywords";
    const columns = ["Business","Status","Tags","Risk","Level","Date"];
    const pageSize = 10;
    const rowRenderer = (business) => {
        return (
          <tr key={business.uid} onClick={() => navigateToBusinessDetails(business.uid)} style={{cursor: "pointer"}}>
            <td>
                <Typography fontWeight={500}>
                    {business.businessName}
                </Typography>
            </td>
            <td><StatusTag status={business.status.status}/></td>
            <td>
                { business.status.tags && business.status.tags.length > 0 && business.status.tags.slice(0, 2).map((tag) => (
                    <Chip key={tag} variant="soft" color={business.status.status === "rejected" ? "danger" : "neutral"} size="sm">{tag}</Chip>
                ))}
                { business.status.tags && business.status.tags.length > 2 &&
                    <Tooltip id={`tooltip-${business.uid}`} variant="plain" title={
                        business.status.tags.slice(2).map((tag) => (
                            <Typography level="body-xs">{tag}</Typography>
                        ))
                    }>
                        <Chip variant="soft" color={business.status.status === "rejected" ? "danger" : "neutral"} size="sm">+{business.status.tags.length - 2}</Chip>
                    </Tooltip>
                }
            </td>
            <td><RiskTag risk={business.status.risk}/></td>
            <td>{business.level.name}</td>
            <td>
                {format(business.createdAt.toDate(), "dd/MM/yyyy' 'HH:mm")}
            </td>
          </tr>
        );
    };

    const handleFilters = (e) => {
        const q = [];
        if(e.status?.length > 0){
            q.push(where("status.status", "in", e.status));
        }
        if(e.level?.length > 0){
            q.push(where("level.name", "==", e.level));
        }
        if(e.risk?.length > 0){
            q.push(where("status.risk", "in", e.risk));
        }
        if(e.country?.length > 0){
            q.push(where("country", "==", e.country));
        }
        if(e.date?.start && e.date?.start !== ''){
            q.push(where("createdAt", ">=", Timestamp.fromDate(new Date(e.date.start))));
        }
        if(e.date?.end && e.date?.end !== ''){
            q.push(where("createdAt", "<=", Timestamp.fromDate(new Date(e.date.end))));
        }
        setFilters(e);
        setFilterConstraints(q);
    }

    return (
        <Page>
            <Header title="Businesses" controls={headerButtons()}/>
            <Grid>
                <Grid xs={12}>
                    <DataTable
                        key={refresh}
                        tableTitle="Businesses"
                        dataQuery={dataQuery}
                        columns={columns}
                        rowRenderer={rowRenderer}
                        pageSize={pageSize}
                        searchField={searchField}
                        filterForm={<BusinessFilterForm levels={levels} setLevels={setLevels} filters={filters} handleFilters={handleFilters}/>}
                        filters={filterConstraints}
                        setFilters={setFilters}
                    />
                </Grid>
            </Grid>
            <Drawer anchor="right" open={showModal} onClose={handleClose}>
                <ModalClose />
                <DialogTitle>Create Business</DialogTitle>
                <Stack direction="column" p={1.5} spacing={1}>
                { showCreateDrawer && selectedLevel ?
                    <BusinessCreateForm level={selectedLevel} onComplete={onComplete}/>
                    :
                    link ?
                    <QRLink link={link} onClose={handleClose}/>
                    :
                    <>
                    <LevelSelectForm type="kyb" handleOnChange={handleLevelSelect} selectedLevel={selectedLevel} />
                    <Button
                        size="sm"
                        disabled={!selectedLevel}
                        onClick={() => setShowCreateDrawer(true)}
                    >
                        Create Business
                    </Button>
                    <Button
                        size="sm"
                        variant="soft" 
                        loading={linkLoading}
                        disabled={!selectedLevel || linkLoading}
                        onClick={handleCreateLink}>
                            Create Link
                    </Button>
                    </>
                }
                </Stack>
            </Drawer>
        </Page>
    );
}
