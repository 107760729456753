import { useCompany } from '../../firebase/auth';
import SubscriptionInactive from './SubscriptionInactive';
import Resubscribe from './Resubscribe';
import TrialExpiration from './TrialExpiration';


export default function Subscription() {

    const { authCompany } = useCompany();

    if(authCompany?.subscription?.status === 'paused'){
        return <TrialExpiration />
    }
    else if(authCompany?.subscription?.status === 'canceled'){
        return <Resubscribe />
    }
    else if(authCompany?.subscription?.status){
        return <SubscriptionInactive />
    }

}