import { useFormik } from 'formik';
import { transactionTypes, transactionCurrencies } from '../../utils/constants';

import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Autocomplete from "@mui/joy/Autocomplete";
import Stack from "@mui/joy/Stack";

export default function TransactionFilterForm({ filters, handleFilters }) {

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            type: filters.type || [],
            currency: filters.currency || [],
            amount: filters.amount || { min: '', max: '' },
            date: filters.date || { start: '', end: '' },
            account: filters.account || [],
        },
        onSubmit: (values) => {
            handleFilters(values);
        },
        onReset: () => {
            handleFilters({
                type: [],
                account: [],
                currency: [],
                amount: { min: '', max: '' },
                date: { start: '', end: '' }
            });
        }
    });

    return (
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <Stack direction="column" spacing={2}>
                <FormControl>
                    <FormLabel>Date</FormLabel>
                    <Grid container spacing={2}>
                        <Grid xs={6}>
                            <Input name="date.start" type="date" value={formik.values.date.start} onChange={formik.handleChange} />
                        </Grid>
                        <Grid xs={6}>
                            <Input name="date.end" type="date" value={formik.values.date.end} onChange={formik.handleChange} />
                        </Grid>
                    </Grid>
                </FormControl>
                <FormControl>
                    <FormLabel>Type</FormLabel>
                    <Autocomplete
                        value={formik.values.type}
                        options={transactionTypes}
                        onChange={(event, newValue) => formik.setFieldValue("type", newValue)}
                        renderInput={(params) => <Input {...params} />}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Amount</FormLabel>
                    <Grid container spacing={2}>
                        <Grid xs={6}>
                            <Input name="amount.min" type="number" placeholder="Min" value={formik.values.amount.min} onChange={formik.handleChange} />
                        </Grid>
                        <Grid xs={6}>
                            <Input name="amount.max" type="number" placeholder="Max" value={formik.values.amount.max} onChange={formik.handleChange} />
                        </Grid>
                    </Grid>
                </FormControl>
                <FormControl>
                    <FormLabel>Currency</FormLabel>
                    <Autocomplete
                        freeSolo={true}
                        value={formik.values.currency}
                        options={transactionCurrencies}
                        onChange={(event, newValue) => formik.setFieldValue("currency", newValue)}
                        onInputChange={(e, value) => formik.setFieldValue('currency', value)} // This captures the free input
                        renderInput={(params) => <Input {...params} />}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Account</FormLabel>
                    <Input name="account" value={formik.values.account} onChange={formik.handleChange} />
                </FormControl>
                <Stack direction="column" spacing={1}>
                    <Button size="sm" type="submit">Apply Filters</Button>
                    <Button size="sm" type="reset" color="neutral" variant="soft">Clear Filters</Button>
                </Stack>
            </Stack>
        </form>
    );
}
