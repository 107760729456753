import AuthControl from './AuthControl';
import Typography from '@mui/joy/Typography';

export default function CustomerInfoTable({ customer }) {
    return(
        <table>
            <tr>
                <td>
                    <Typography level="title-sm">ID Number</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{customer.idNumber}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography level="title-sm">Name</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{customer.firstName} {customer.lastName}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography level="title-sm">Nationality</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{customer.nationality}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography level="title-sm">Birth Date</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{customer.dateofbirth}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography level="title-sm">Email</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{customer.email}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography level="title-sm">Phone</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{customer.phone}</Typography>
                </td>
            </tr>
            <AuthControl.AdminOrOfficer>
                {
                    customer.fields && customer.fields.map((field) => (
                        <tr key={field.key}>
                            <td>
                                <Typography level="title-sm">{field.name}</Typography>
                            </td>
                            <td>
                                <Typography level="body-sm">{field.value}</Typography>
                            </td>
                        </tr>
                    )) 
                }
            </AuthControl.AdminOrOfficer>
        </table>
    );
}