import { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from './../../firebase/firebase';

import Stack from '@mui/joy/Stack';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import Typography from '@mui/joy/Typography';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalOverflow from '@mui/joy/ModalOverflow';
import DialogTitle from '@mui/joy/DialogTitle';

import { Check } from '@mui/icons-material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { useSnackbar } from '../../utils/SnackbarContext';

export default function Resubscribe() {

    const showSnackbar = useSnackbar();

    const createCheckoutLink = (priceId) => {
        setLinkLoading(true);
        httpsCallable(functions, 'createStripeCheckout')({priceId})
        .then((result) => {
            window.location.href = result.data.url;
        })
        .catch((error) => {
            showSnackbar("An error occurred: " + error, "danger");
            setLinkLoading(false);
        });
    }

    const plans = [
        {
            title: 'Basic',
            price: '$89',
            priceId: "price_1PVBtR03GPWhC1k926pTghiO",
            description: 'Easiest way to get started for small and medium sized businnesses.',
            features: [
                '100 verifications per month',
                'KYC Verification',
                'Transaction Monitoring',
                'Remote Verifications',
            ]
        },
        {
            title: 'Plus',
            price: '$149',
            priceId: "price_1PWexZ03GPWhC1k9NCMhClhf",
            description: 'For businesses that need more verifications and advanced features.',
            highlight: true,
            features: [
                '500 verifications per month',
                'KYC Verification',
                'Transaction Monitoring',
                'Remote Verifications',
                'Custom Branding',
            ]
        },
        {
            title: 'Pro',
            price: '$239',
            priceId: "price_1PWf1w03GPWhC1k904LpfV0i",
            description: 'For large businesses that need unlimited verifications and premium support.',
            features: [
                'Unlimited verifications per month',
                'KYC Verification',
                'Transaction Monitoring',
                'Remote Verifications',
                'Custom Branding',
                'Premium Support',
            ]
        }
        

    ]

    const [linkLoading, setLinkLoading] = useState(false);

    const PlanCard = ({ title, description, price, priceId, features, highlight=false }) => {
        return (
        <Card 
        size="lg"
        variant={highlight ? "solid" : "outlined"}
        color={highlight ? "primary" : "neutral"}
        invertedColors={highlight}
        >
            <Stack direction="row" justifyContent="space-between">
                <Typography level="h2">{title}</Typography>
                { highlight &&
                <Typography level="body-md">
                <Chip>
                    Best Deal
                </Chip>
                </Typography>
                }
            </Stack>
            <Typography level="h2" sx={{ mr: 'auto' }}>
                {price}{' '}
                <Typography level="body-md" fontWeight={400}>
                / month
                </Typography>
            </Typography>
            <Typography level="body-md">{description}</Typography>
            <Divider/>
            <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
            {
                features.map(feature => 
                    <ListItem>
                        <ListItemDecorator>
                        <Check />
                        </ListItemDecorator>
                        {feature}
                    </ListItem>
                )
            }
            </List>
            <CardActions>
            <Button
                size="sm"
                endDecorator={<KeyboardArrowRight />}
                onClick={()=>createCheckoutLink(priceId)}
                loading={linkLoading}
                disabled={linkLoading}
            >
                Select Plan
            </Button>
            </CardActions>
        </Card>
        );
    }

    return (
        <Modal open={true}>
            <ModalOverflow>
            <ModalDialog>
                <DialogTitle>Your subscription is canceled</DialogTitle>
                <Typography level="body-sm" mb={2}>
                    Your subscription is currently canceled. Please choose a plan to reactivate your subscription.
                </Typography>
                <Stack direction={{xs:"column", md:"row"}} spacing={2}>
                    { plans.map(plan => 
                        <PlanCard {...plan}/>
                    )}
                </Stack>
                <Button size="sm" variant="soft" color="neutral"
                onClick={()=>{window.location.href = '/logout'}}>Logout</Button>
            </ModalDialog>
            </ModalOverflow>
        </Modal>
  );
}