import { useState } from 'react';
import { useAuth } from '../../firebase/auth';
import { functionsURL } from '../../firebase/firebase';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import { useSnackbar } from '../../utils/SnackbarContext';

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().required('Required'),
    otp: Yup.string().when('otpSent', {
        is: true,
        then: Yup.string().required('Required')
    })
});

export default function LoginForm() {

    const showSnackbar = useSnackbar();
    const { login } = useAuth();
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            email: '',
            password: '',
            otp: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            const { email, password, otp } = values;
            if (otpSent) {
                fetch(`${functionsURL}/verifyLoginOTP`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ email, password, code: otp })
                })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        showSnackbar("Login successful, redirecting to dashboard", 'success');
                        login(data.token);
                    } else {
                        if(data.message === "You need to verify your email address. Please check your email for verification link"){
                            setOtpSent(false);
                            showSnackbar(data.message, "warning");
                        }else{
                            formik.setErrors({ otp: "Verification failed" });
                        }
                    }
                })
                .catch(error => {
                    showSnackbar(error.message, 'danger');
                    formik.setErrors({ otp: "Verification failed" });
                })
                .finally(() => setLoading(false));
            } else {
                fetch(`${functionsURL}/getLoginOTP`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ email, password })
                })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setOtpSent(true);
                    } else if(data.code === "9991"){
                        showSnackbar(data.message, 'warning');
                    } else {
                        formik.setErrors({ login: "Invalid login credentials" });
                    }
                })
                .catch(error => showSnackbar(error.message, 'danger'))
                .finally(() => setLoading(false));
            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            {!otpSent ? (
                <Stack direction="column" spacing={1}>
                    <Typography level="h2">Login</Typography>
                    <FormControl error={!!formik.errors.login || !!formik.errors.email}>
                        <FormLabel>Email</FormLabel>
                        <Input
                            type="text"
                            name="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                        {formik.errors.email ? (
                            <FormHelperText>{formik.errors.email}</FormHelperText>
                        ) : null}
                    </FormControl>
                    <FormControl error={!!formik.errors.login || !!formik.errors.password}>
                        <Stack direction="row" justifyContent="space-between">
                            <FormLabel>Password</FormLabel>
                            <RouterLink to="/forgotpassword" tabIndex={-1}>
                                <Link level="body-sm">Forgot your password?</Link>
                            </RouterLink>
                        </Stack>
                        <Input
                            type="password"
                            name="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                        />
                        {formik.errors.password ? (
                            <FormHelperText>{formik.errors.password}</FormHelperText>
                        ) : null}
                        {formik.errors.login ? (
                            <FormHelperText>{formik.errors.login}</FormHelperText>
                        ) : null}
                    </FormControl>
                    <Button size="sm" type="submit" loading={loading} disabled={loading}>
                        Login
                    </Button>
                    <Box textAlign={"end"}>
                        <RouterLink to="/register">
                            <Link level="body-sm">Need an account? Sign up!</Link>
                        </RouterLink>
                    </Box>
                </Stack>
            ) : (
                <Stack direction="column" spacing={1}>
                    <Typography level="h2">Verify Your Phone</Typography>
                    <Typography level="body-md">Please verify the OTP code we sent to your phone number</Typography>
                    <FormControl error={formik.touched.otp && !!formik.errors.otp}>
                        <FormLabel>Enter Code</FormLabel>
                        <Input
                            type="text"
                            name="otp"
                            onChange={formik.handleChange}
                            value={formik.values.otp}
                        />
                        {formik.touched.otp && formik.errors.otp ? (
                            <FormHelperText>{formik.errors.otp}</FormHelperText>
                        ) : null}
                    </FormControl>
                    <Button size="sm" type="submit" loading={loading} disabled={loading}>
                        Verify
                    </Button>
                </Stack>
            )}
        </form>
    );
}
