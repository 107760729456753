import { useState } from "react";
import { inviteMember } from "../../firebase/members";
import { useFormik } from "formik";
import * as Yup from "yup";

import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import { FormHelperText } from "@mui/joy";
import { useSnackbar } from "../../utils/SnackbarContext";
import { useAuth } from "../../firebase/auth";

export default function MemberCreateForm({ closeDrawer }) {
    const userRoles = [
        {
            label: "Admin",
            value: "admin"
        },
        {
            label: "Compliance Officer",
            value: "officer"
        },
    ];

    const { authCompany } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const showSnackbar = useSnackbar();

    const validationSchema = Yup.object({
        email: Yup.string().email("Invalid email address").required("Email is required"),
        role: Yup.string().required("Role is required")
    });

    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            email: "",
            role: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            if (authCompany?.subscription?.limits?.members <= 0) {
                setIsLoading(false);
                showSnackbar("You have reached the maximum number of members allowed", "danger");
                return;
            }
            const result = await inviteMember(values);
            setIsLoading(false);
            if (result.success) {
                showSnackbar("Invitation sent successfully!", "success");
                closeDrawer();  // Close the drawer after sending an invitation
            } else {
                showSnackbar(result.message, "danger");
            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={1}>
                <FormControl error={!!formik.errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input
                        required
                        type="email"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                    {formik.errors.email && (
                        <FormHelperText error>{formik.errors.email}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={!!formik.errors.role}>
                    <FormLabel>User Role</FormLabel>
                    <Select
                        required
                        placeholder="Select a role"
                        name="role"
                        onChange={(e, newValue) => formik.setFieldValue("role", newValue)}
                        value={formik.values.role}
                    >
                        {userRoles.map((userRole) => (
                            <Option key={userRole.value} value={userRole.value}>
                                {userRole.label}
                            </Option>
                        ))}
                    </Select>
                    {formik.errors.role && (
                        <FormHelperText error>{formik.errors.role}</FormHelperText>
                    )}
                </FormControl>
                <Button size="sm" type="submit" loading={isLoading} disabled={isLoading}>
                    Submit
                </Button>
            </Stack>
        </form>
    );
}
