import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, sendEmailVerification } from 'firebase/auth';
import { getFirestore , connectFirestoreEmulator} from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// Configure Firebase.
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); 
const functions = getFunctions(app, "europe-west1");

let functionsURL = process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL;

if(process.env.REACT_APP_ENV === "local") {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
  connectStorageEmulator(storage, "127.0.0.1", 9199);
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
  functionsURL = `http://${window.location.hostname}:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/europe-west1`;
}

export { app, auth, db, storage, functions, functionsURL, sendEmailVerification };