import { useFormik } from "formik";
import * as Yup from "yup";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";

const buildValidationSchema = (fields) => {
    const fieldSchemas = fields.reduce((acc, field) => {
        acc[field.name] = Yup.string().required('Required');
        return acc;
    }, {});
    return Yup.object().shape(fieldSchemas);
};

export default function LinkKYCAdditionalInformationForm({ useLinkKYC }) {

    const { handleAdditionalInformation, isLoading, level } = useLinkKYC();
    const validationSchema = buildValidationSchema(level.fields);

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: level.fields.reduce((acc, field) => {
            acc[field.name] = '';
            return acc;
        }, {}),
        validationSchema,
        onSubmit: (values) => {
            handleAdditionalInformation(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography level="h3" mb={2}>Additional Information</Typography>
            <Stack direction="column" spacing={2}>
                {level.fields.map((field) => (
                    <FormControl
                        key={field.name}
                        error={formik.touched[field.name] && !!formik.errors[field.name]}
                    >
                        <FormLabel>{field.name}</FormLabel>
                        <Input
                            required
                            type="text"
                            name={field.name}
                            value={formik.values[field.name]}
                            onChange={formik.handleChange}
                        />
                        {formik.touched[field.name] && formik.errors[field.name] && (
                            <FormHelperText>{formik.errors[field.name]}</FormHelperText>
                        )}
                    </FormControl>
                ))}
                <Button size="sm" type="submit" disabled={isLoading} loading={isLoading}>Next</Button>
            </Stack>
        </form>
    );
}