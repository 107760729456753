import Stack from "@mui/joy/Stack"
import Box from "@mui/joy/Box"
import Typography from "@mui/joy/Typography"
import Divider from "@mui/joy/Divider"

export default function FooterAuth() {
    return (
        <Box>
            <Divider />
            <Stack direction="row" justifyContent="space-between" mt={2}>
                <Box>
                    <Typography level="body-xs">Copyright &copy; TrueKYC {new Date().getFullYear()}</Typography>
                </Box>
                <Box>
                    <Typography level="body-xs">Privacy Policy &middot; Terms &amp; Conditions</Typography>
                </Box>
            </Stack>
        </Box>
    )
}
