import { functionsURL } from './firebase';

export const registerUser = async (data) => {
    return await fetch(functionsURL+'/registerUser', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
}

export const getRegisterOTP = async (data) => {
    return await fetch(functionsURL+'/getRegisterOTP', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
}

export const getPasswordResetOTP = async (data) => {
    return await fetch(functionsURL+'/getPasswordResetOTP', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
}

export const verifyPasswordResetOTP = async (data) => {
    return await fetch(functionsURL+'/verifyPasswordResetOTP', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
}

export const createPasswordResetLink = async (data) => {
    return await fetch(functionsURL+'/createPasswordResetLink', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
}