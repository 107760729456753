import Chip from '@mui/joy/Chip';

import { Check, Clear } from '@mui/icons-material';

export default function VerifiedTag({verified, soft=true}){

    return(
        <Chip 
            size="sm" 
            variant={soft ? "soft" : "solid"} 
            startDecorator={
                verified ? <Check /> : <Clear />
            }
            color={
                verified ? 'success' : 'warning'
            }
        >
            {verified ? "Verified" : "Not Verified"}
        </Chip>
    );
}