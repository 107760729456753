import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Register from './pages/auth/Register';
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import PasswordReset from './pages/auth/PasswordReset';
import Logout from './pages/auth/Logout';
import Dashboard from './pages/Dashboard';
import PrivateRoute from './utils/PrivateRoute';
import PublicOnlyRoute from './utils/PublicOnlyRoute';
import LinkKYC from './pages/link/LinkKYC';
import LinkKYB from './pages/link/LinkKYB';
import LinkKYCResubmission from './pages/link/LinkKYCResubmission';
import LinkKYBResubmission from './pages/link/LinkKYBResubmission'; // Import KYB resubmission component
import MemberRegistration from './pages/members/MemberRegistration';
import { useAuth } from './firebase/auth';

function App() {
  const { authUser } = useAuth();

  return (
    <Router>
      { authUser ?
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path='/*' element={<Dashboard />} />
            <Route path='logout' element={<Logout />} />
          </Route>
        </Routes>
        :
        <Routes>
          <Route element={<PublicOnlyRoute />}>
            <Route path='*' element={<Navigate to="/login" />} />
            <Route path='/login' element={<Login />} />
            <Route path='/forgotpassword' element={<ForgotPassword />} />
            <Route path='/resetpassword/:linkId' element={<PasswordReset />} />
            <Route path='/invitations/:invitationId' element={<MemberRegistration />} />
            <Route path='/link/kyc/:linkId' element={<LinkKYC />} />
            <Route path='/link/kyb/:linkId' element={<LinkKYB />} />
            <Route path='/resubmission/kyc/:linkId' element={<LinkKYCResubmission />} />
            <Route path='/resubmission/kyb/:linkId' element={<LinkKYBResubmission />} />
            <Route path='/register' element={<Register />} />
          </Route>
        </Routes>
      } 
    </Router>
  );
}

export default App;
