import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../firebase/auth';

import { db } from '../../firebase/firebase';
import { doc, onSnapshot } from "firebase/firestore";

import CustomerProfileTransactions from './CustomerProfileTransactions';
import CustomerProfileVerification from './CustomerProfileVerification';
import Page from '../../layout/Page';
import TabListStyled from '../../components/TabListStyled';
import CustomerHeader from '../../layout/CustomerHeader';
import Header from '../../layout/Header';
import CustomerProfileNotes from './CustomerProfileNotes';

import Tab from '@mui/joy/Tab';
import Tabs from "@mui/joy/Tabs"
import TabPanel from "@mui/joy/TabPanel"
import { useSnackbar } from '../../utils/SnackbarContext';

const CustomerContext = createContext(null);

export function useCustomer(){
    return useContext(CustomerContext);
}

export function CustomerProfile() {
    
    const { authUser } = useAuth();

    const customerId = useParams().customerId;
    const [customer, setCustomer] = useState();
    const [loading, setLoading] = useState();
    const showSnackbar = useSnackbar();
    
    const navigate = useNavigate();

    // fetch customer document
    useEffect(() => {
        const docRef = doc(db, "companies", authUser.company.uid, "customers", customerId);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (!doc.exists()) {
                navigate("..", { relative: "path" });
                return;
            }
            setCustomer({
                uid: doc.id,
                ...doc.data(),
            });
        }, (error) => {
            showSnackbar("An error occurred: " + error, "danger");
        });

        return () => unsubscribe();
    }, [customerId, navigate, authUser.company.uid]);

    if(!customer){
        return null;
    }
    return (
        <CustomerContext.Provider value={customer}>
            <Page>
                <Tabs aria-label="Customer Profile Tabs" defaultValue="verification" sx={{bgcolor: "transparent"}}>
                    <Header
                        customTitleContent={
                            <CustomerHeader/>
                        }
                        tabs={
                            <TabListStyled>
                                <Tab value="verification">Verification</Tab>
                                { customer.status.status === 'approved' && 
                                    <Tab value="transactions">Transactions</Tab>
                                }
                                    <Tab value="notes">Notes</Tab>
                            </TabListStyled>
                        }
                    />
                    <TabPanel keepMounted sx={{px:0}} value="verification">
                        <CustomerProfileVerification />
                    </TabPanel>
                    <TabPanel keepMounted sx={{px:0}} value="transactions">
                        <CustomerProfileTransactions />
                    </TabPanel>
                    <TabPanel keepMounted sx={{px:0}} value="notes">
                        <CustomerProfileNotes />
                    </TabPanel>
                </Tabs>
            </Page>
        </CustomerContext.Provider>
    );
};