const defaultFields = [
    "ID Number",
    "First Name",
    "Last Name",
    "Date of Birth",
    "Nationality",
    "Phone",
    "Email"
];

const defaultKYBFields = [
    "Email",
    "Company Name",
    "Country of Incorporation",
    "Registered Address",
    "Tax Number",
    "Incorporation no",
];

const optionalFields = [
    {name:"Place of Birth"},
    {name:"Address"},
    {name:"TIN"},
    {name:"Driving License Number"},
    {name:"Passport Number"},
    {name:"Residence Permit Number"},
    {name:"Work Permit Number"}
];

const optionalKYBFields = [
    {name:"Industry"},
    {name:"Company Phone"},
    {name:"Company Web Page"},
];

const documents = [
    {
        "name":"ID Card",
        "files":["Front","Back"]
    },
    {
        "name":"Passport",
        "files":["Passport"]
    },
    {
        "name":"Driving License",
        "files":["Front","Back"]
    },
    {
        "name":"Residence Permit",
        "files":["Residence Permit"]
    },
    {
        "name":"Proof of Address",
        "files":["Proof of Address"]
    },
    {
        "name":"Proof of Payment",
        "files":["Proof of Payment"]
    },
    {
        "name":"Bank Statement",
        "files":["Bank Statement"]
    },
    {
        "name":"Student ID",
        "files":["Front","Back"]
    },
    {
        "name":"Work Permit",
        "files":["Work Permit"]
    },
    {
        "name":"Tax Document",
        "files":["Tax Document"]
    }
];

const documentsKYB = [
    {
        "name":"Operating certificate",
        "files":["Operating certificate"]
    },
    {
        "name":"Trade registry documents",
        "files":["Trade registry documents"]
    },
    {
        "name":"Tax plate",
        "files":["Tax plate"]
    },
    {
        "name":"Proof of address",
        "files":["Proof of address"]
    },
    {
        "name":"Bank Statement",
        "files":["Bank Statement"]
    },
    {
        "name":"Signature Circulars",
        "files":["Signature Circulars"]
    },
    {
        "name":"Shareholders ID",
        "files":["Shareholders ID"]
    },
    {
        "name":"Directors ID",
        "files":["Directors ID"]
    },
    {
        "name":"Directors Passport",
        "files":["Directors Passport"]
    },
];

const verifications = [
    "email",
    "sms"
];

const transactionTypes = [
    "Transfer In",
    "Transfer Out",
    "Deposit",
    "Withdrawal",
    "Payment",
    "Crypto Payment",
    "Crypto Sales",
    "Crypto - Fiat Conversion",
    "Currency Exchange",
    "Gold Exchange",
    "Accomodation",
    "Car Rental",
]

const transactionCurrencies = [
    "USD", "EUR", "GBP", "AUD", "CAD", "CHF", "JPY", "TRY", "BTC", "ETH", 
    "USDT", "USDC"
]

export { defaultFields, defaultKYBFields, optionalFields, optionalKYBFields, documents, documentsKYB, verifications, transactionTypes, transactionCurrencies };
