import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { registerUser, getRegisterOTP } from "../../firebase/register";

import Stack from "@mui/joy/Stack";
import Grid from "@mui/joy/Grid";
import Button from "@mui/joy/Button";
import FormLabel from "@mui/joy/FormLabel";
import Typography from "@mui/joy/Typography";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from '@mui/joy/FormHelperText';
import Input from "@mui/joy/Input";
import { useSnackbar } from "../../utils/SnackbarContext";
import PhoneInput from '../../components/form/PhoneInput';
import PasswordRequirements from '../../components/PasswordRequirements';

const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().required('Phone number is required'),
    password: Yup.string().required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(20, 'Password cannot be longer than 20 characters')
        .matches(/(?=.*[A-Z])/, 'Password must have at least one uppercase letter')
        .matches(/(?=.*[a-z])/, 'Password must have at least one lowercase letter')
        .matches(/(?=.*[0-9])/, 'Password must have at least one number'),
    passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is required'),
    otp: Yup.string().when('otpSent', {
        is: true,
        then: Yup.string().required('OTP is required')
    }),
});

export default function MemberRegistrationForm() {
    const { invitationId } = useParams();
    const showSnackbar = useSnackbar();
    const [otpSent, setOtpSent] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const navigate = useNavigate();

    const formik = useFormik({
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
            passwordConfirm: '',
            otp: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            if (otpSent) {
                handleSignUp(values);
            } else {
                handleSendOTP(values);
            }
        }
    });

    const handleSendOTP = async (values) => {
        const data = {
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phone,
            invitationCode: invitationId
        };
        
        setLoading(true);
        try {
            const response = await getRegisterOTP(data);
            const result = await response.json();
            if (result.success) {
                setOtpSent(true);
                showSnackbar("OTP sent successfully", "success");
            } else {
                showSnackbar(result.message, "danger");
            }
        } catch (error) {
            showSnackbar(error.message, "danger");
        }
        finally{
            setLoading(false);
        }
    };

    const handleSignUp = async (values) => {
        const data = {
            email: values.email,
            password: values.password,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phone,
            otpCode: values.otp,
            invitationCode: invitationId
        }
        
        setLoading(true);
        try {
            const response = await registerUser(data);
            const result = await response.json();
            showSnackbar(result.message, result.success ? "success" : "danger");
            if (result.success) {
                showSnackbar("User registered successfully. Please check your email for verification.", "success");
                navigate('/login');
                formik.resetForm();
            }
        } catch (error) {
            showSnackbar(error.message, "danger");
        }
        finally{
            setLoading(false);
        }
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack direction="column" spacing={1}>
                {!otpSent ? (
                    <>
                        <Grid container justifyContent="space-between">
                            <Grid xs={6}>
                                <FormControl error={!!formik.errors.firstName} sx={{ marginRight: 0.5 }}>
                                    <FormLabel>First Name</FormLabel>
                                    <Input
                                        type="text"
                                        name="firstName"
                                        onChange={formik.handleChange}
                                        value={formik.values.firstName}
                                    />
                                    {formik.errors.firstName && (
                                        <FormHelperText>{formik.errors.firstName}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid xs={6}>
                                <FormControl error={!!formik.errors.lastName} sx={{ marginLeft: 0.5 }}>
                                    <FormLabel>Last Name</FormLabel>
                                    <Input
                                        type="text"
                                        name="lastName"
                                        onChange={formik.handleChange}
                                        value={formik.values.lastName}
                                    />
                                    {formik.errors.lastName && (
                                        <FormHelperText>{formik.errors.lastName}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Grid>
                        <FormControl error={!!formik.errors.email}>
                            <FormLabel>Email</FormLabel>
                            <Input
                                type="email"
                                name="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                            />
                            {formik.errors.email && (
                                <FormHelperText>{formik.errors.email}</FormHelperText>
                            )}
                        </FormControl>
                        <FormControl error={!!formik.errors.phone}>
                            <FormLabel>Phone Number</FormLabel>
                            <PhoneInput
                                name="phone"
                                setFieldValue={formik.setFieldValue}
                                formik={formik}
                            />
                            {formik.errors.phone && (
                                <FormHelperText>{formik.errors.phone}</FormHelperText>
                            )}
                        </FormControl>
                        <FormControl error={!!formik.errors.password}>
                            <FormLabel>Password</FormLabel>
                            <Input
                                type="password"
                                name="password"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                            />
                            <PasswordRequirements password={formik.values.password} />
                            {formik.errors.password && (
                                <FormHelperText>{formik.errors.password}</FormHelperText>
                            )}
                        </FormControl>
                        <FormControl error={!!formik.errors.passwordConfirm}>
                            <FormLabel>Confirm Password</FormLabel>
                            <Input
                                type="password"
                                name="passwordConfirm"
                                onChange={formik.handleChange}
                                value={formik.values.passwordConfirm}
                            />
                            {formik.errors.passwordConfirm && (
                                <FormHelperText>{formik.errors.passwordConfirm}</FormHelperText>
                            )}
                        </FormControl>
                        <Button size="sm" type="submit" loading={isLoading} disabled={isLoading}>
                            Register
                        </Button>
                    </>
                ) : (
                    <>
                        <Typography level="body-md">We sent an OTP code to number: {formik.values.phone}</Typography>
                        <FormControl error={!!formik.errors.otp}>
                            <FormLabel>OTP Code</FormLabel>
                            <Input
                                type="text"
                                name="otp"
                                onChange={formik.handleChange}
                                value={formik.values.otp}
                            />
                            {formik.errors.otp && (
                                <FormHelperText>{formik.errors.otp}</FormHelperText>
                            )}
                        </FormControl>
                        <Button size="sm" type="submit" loading={isLoading} disabled={isLoading}>
                            Register
                        </Button>
                    </>
                )}
            </Stack>
        </form>
    );
}
