import React from 'react';
import Sidebar from './Sidebar';
import NavbarMobile from './NavbarMobile';

import Box from '@mui/joy/Box';

const Layout = ({children}) =>{

    return (
        <Box sx={{ display: 'flex', minHeight: '100svh' }}>
            <Sidebar />
            <NavbarMobile />
            {children}
        </Box>
    )
}

export default Layout;