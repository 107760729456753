import Chip from '@mui/joy/Chip';

import { AccessTime, Check, Clear, MoreHoriz, Refresh } from '@mui/icons-material';

export default function StatusTag({status, soft=true}){

    const statusColors = {
        "pending": 'primary',
        "approved": 'success',
        "rejected": 'danger',
        "in progress": 'warning',
        "resubmission": 'neutral',
    }

    const icons = {
        "approved": <Check />,
        "pending": <AccessTime />,
        "rejected": <Clear />,
        "in progress": <MoreHoriz />,
        "resubmission": <Refresh />,
    }

    return(
        <Chip 
            size="sm" 
            variant={soft ? "soft" : "solid"} 
            startDecorator={icons[status]}
            color={statusColors[status]}
        >
            {status.charAt(0).toUpperCase() + status.slice(1)}
        </Chip>
    );
}