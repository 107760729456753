import Page from '../../layout/Page';
import { useAuth } from '../../firebase/auth';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { doc, updateDoc } from "firebase/firestore";
import { db } from '../../firebase/firebase';

import CustomModal from '../../components/CustomModal';
import TabListStyled from '../../components/TabListStyled';
import Header from '../../layout/Header';

import Button from "@mui/joy/Button";
import Tab from '@mui/joy/Tab';
import Tabs from "@mui/joy/Tabs";
import TabPanel from "@mui/joy/TabPanel";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import IconButton from "@mui/joy/IconButton";
import Add from '@mui/icons-material/Add';
import LevelsKYC from './LevelsKYC';
import LevelsKYB from './LevelsKYB';

export default function Levels() {
    const { authUser } = useAuth();
    const [levelToDelete, setLevelToDelete] = useState();
    const [isUpdating, setIsUpdating] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const navigate = useNavigate();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuSelect = (type) => {
        navigate(`./create/${type}`);
        setAnchorEl(null);
    };

    const deleteLevel = (levelId) => {
        const levelRef = doc(db, "companies", authUser.company.uid, "levels", levelId);
        updateDoc(levelRef, {
            isDeleted: true
        })
        .catch((error) => {
            alert("Error deleting level: ", error);
        });
    };

    const handleLevelEdit = (levelId) => {
        navigate(`./edit/${levelId}`);
    };

    const handleDeleteConfirmation = () => {
        setIsUpdating(true);
        deleteLevel(levelToDelete);
        setLevelToDelete();
        setIsUpdating(false);
    };

    return (
        <Page>
            <Tabs aria-label="Customer Profile Tabs" defaultValue="kyc" sx={{bgcolor: "transparent"}}>
                <Header title="Levels" 
                    tabs={
                        <TabListStyled>
                            <Tab value="kyc">KYC</Tab>
                            <Tab value="kyb">KYB</Tab>
                        </TabListStyled>
                    }
                    controls={
                        <>
                            <Button
                                size="sm"
                                onClick={handleMenuClick}
                                startDecorator={<Add />}
                            >
                                Create New
                            </Button>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem onClick={() => handleMenuSelect('kyc')}>KYC Level</MenuItem>
                                <MenuItem onClick={() => handleMenuSelect('kyb')}>KYB Level</MenuItem>
                            </Menu>
                        </>
                    }
                />
                <TabPanel keepMounted sx={{px:0}} value="kyc">
                    <LevelsKYC isUpdating={isUpdating} setLevelToDelete={setLevelToDelete} handleLevelEdit={handleLevelEdit}/>
                </TabPanel>
                <TabPanel keepMounted sx={{px:0}} value="kyb">
                    <LevelsKYB isUpdating={isUpdating} setLevelToDelete={setLevelToDelete} handleLevelEdit={handleLevelEdit}/>
                </TabPanel>
            </Tabs>
            <CustomModal.Delete
                open={levelToDelete}
                title="Confirm Deletion"
                message="Are you sure you want to delete this level?"
                onClose={() => setLevelToDelete()}
                handleDelete={handleDeleteConfirmation}
            />
        </Page>
    );
}
