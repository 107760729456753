import { useState, useEffect } from 'react';
import { query, collection, where, Timestamp } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { useAuth } from '../../firebase/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';

import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import Tabs from "@mui/joy/Tabs";
import Tab from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";

import Page from '../../layout/Page';
import DataTable from '../../components/DataTable';
import TabListStyled from '../../components/TabListStyled';

const pageSize = 10;

export default function TransactionsCreateCustomerSelect() {
    const { authUser } = useAuth();
    const navigate = useNavigate();
    const { type } = useParams();

    const [selectedEntity, setSelectedEntity] = useState(null);
    const [tabValue, setTabValue] = useState(type || 'customer');

    useEffect(() => {
        if (type !== 'customer' && type !== 'business') {
            navigate('/transactions/create/customer');
        } else {
            setTabValue(type);
        }
    }, [type, navigate]);

    const handleSelectEntity = (entity) => setSelectedEntity(selectedEntity === entity ? null : entity);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        navigate(`/transactions/create/${newValue}`);
    };

    const handleSubmit = () => {
        navigate(`/transactions/create/${tabValue}/${selectedEntity.uid}`, {
            state: { type: tabValue, customer: selectedEntity },
        });
    };

    const queries = {
        customer: query(collection(db, 'companies', authUser.company.uid, 'customers'), where("status.status", "==", "approved")),
        business: query(collection(db, 'companies', authUser.company.uid, 'businesses'), where("status.status", "==", "approved")),
    };

    const renderRow = (entity, entityType) => (
        <tr key={entity.uid} onClick={() => handleSelectEntity(entity)}>
            <td>
                <Checkbox checked={selectedEntity === entity} />
            </td>
            <td>
                <Typography fontWeight="lg">
                    {entityType === 'customer' ? `${entity.firstName} ${entity.lastName}` : entity.businessName}
                </Typography>
                <Typography level="body-xs" color="neutral">
                    {entityType === 'customer' ? entity.idNumber : entity.incorporationNumber}
                </Typography>
            </td>
            <td>{format(new Timestamp(entity.createdAt.seconds, entity.createdAt.nanoseconds).toDate(), "dd/MM/yyyy' 'HH:mm")}</td>
            <td>{entity.createdByName}</td>
        </tr>
    );

    return (
        <Page title="Create Transaction">
            <Tabs aria-label="Entity Selection Tabs" value={tabValue} onChange={handleTabChange}>
                <TabListStyled>
                    <Tab value="customer">Customer</Tab>
                    <Tab value="business">Business</Tab>
                </TabListStyled>
                {['customer', 'business'].map((entityType) => (
                    <TabPanel key={entityType} keepMounted sx={{ px: 0 }} value={entityType}>
                        <DataTable
                            dataQuery={queries[entityType]}
                            columns={[entityType === 'customer' ? "Customer" : "Business", "Created At", "Created By"]}
                            rowRenderer={(entity) => renderRow(entity, entityType)}
                            pageSize={pageSize}
                            searchField="keywords"
                            selectColumn
                        />
                    </TabPanel>
                ))}
            </Tabs>
            {selectedEntity && (
                <Stack direction="row" justifyContent="flex-end" mt={2}>
                    <Button size="sm" variant="solid" onClick={handleSubmit}>
                        Create Transaction
                    </Button>
                </Stack>
            )}
        </Page>
    );
}
