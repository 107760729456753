import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import VerifiedTag from "./VerifiedTag";

export default function CustomerProfileInfoTable({ customer }) {

    function Title({ children }){
        return(
            <Typography level="title-sm">{ children }</Typography>
        );
    }

    function Value({ children }){
        return(
            <Typography level="body-sm">{ children }</Typography>
        );
    }

    return (
        <Grid container spacing={1}>
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Title>ID Number</Title>
                <Value>{customer.idNumber}</Value>
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Title>First Name</Title>
                <Value>{customer.firstName}</Value>
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Title>Last Name</Title>
                <Value>{customer.lastName}</Value>
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Title>Nationality</Title>
                <Value>{customer.nationality}</Value>
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Title>Date of Birth</Title>
                <Value>{customer.dateofbirth}</Value>
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Title>Phone {customer.level.verifications.includes("sms") && <VerifiedTag verified={customer.phoneVerified} />}
                </Title>
                <Value>{customer.phone}</Value>
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Title>Email
                    {customer.level.verifications.includes("email") && <VerifiedTag verified={customer.emailVerified} />}
                </Title>
                <Value>{customer.email}</Value>
            </Grid>
            {
                customer.fields && customer.fields.map((field) => (
                    <Grid xs={12} sm={6} md={6} lg={3} key={field.name}>
                        <Title>{field.name}</Title>
                        <Value>{field.value}</Value>
                    </Grid>
                )) 
            }
        </Grid>
    );
}
