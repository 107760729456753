import { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from './../../firebase/firebase';

import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalOverflow from '@mui/joy/ModalOverflow';


import { Check } from '@mui/icons-material';
import { useSnackbar } from '../../utils/SnackbarContext';

export default function TrialExpiration() {

    const [linkLoading, setLinkLoading] = useState(false);
    const showSnackbar = useSnackbar();

    const navigateToPortal = () => {
        setLinkLoading(true);
        httpsCallable(functions, 'createStripePortal')()
        .then((result) => {
            window.location.href = result.data.url;
        })
        .catch((error) => {
            showSnackbar("An error occurred: " + error, "danger");
            setLinkLoading(false);
        });
    }

    const benefits = [
        {
            title: 'Customer Onboarding',
            description: 'Easily onboard customers with a simple and secure process.',
        },
        {
            title: 'KYC Verification',
            description: 'Verify customer identities and reduce fraud.',
        },
        {
            title: 'Transaction Monitoring',
            description: 'Monitor transactions and detect suspicious activities.',
        },
        {
            title: 'Remote Verifications',
            description: 'Verify customers remotely with a secure process.',
        }
    ];

    return (
        <Modal open={true}>
            <ModalOverflow>
                <ModalDialog size="lg">
                    <Typography level="title-lg">Your free trial has expired</Typography>
                    <Typography level="body-sm" mb={2}>If you want to continue to benefit from TrueKYC, it's time to upgrade your plan. If you need to get a trial extension please feel free to contact us.</Typography>
                    <Stack direction="column" mb={2} spacing={1}>
                        { benefits.map(benefit => 
                            <Stack direction="row" spacing={1}>
                                <Check color="success" fontSize="12"/>
                                <Box>
                                    <Typography level="title-sm">{benefit.title}</Typography>
                                    <Typography level="body-sm">{benefit.description}</Typography>
                                </Box>
                            </Stack>
                        )}
                    </Stack>
                    <Stack direction="column" spacing={1}>
                        <Button size="sm" variant="solid" color="primary" onClick={navigateToPortal} loading={linkLoading} disabled={linkLoading}>Manage Subscription</Button>
                        <Button size="sm" variant="soft" color="neutral" onClick={()=>{window.location.href = '/logout'}}>Logout</Button>
                    </Stack>
                </ModalDialog>
            </ModalOverflow>
        </Modal>
  );
}