import { useState } from 'react';
import { createPasswordResetLink } from '../../firebase/register';
import { useFormik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';

import * as Yup from 'yup';

import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";

import { useSnackbar } from '../../utils/SnackbarContext';

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
});

export default function ForgotPasswordForm({setLinkSent}) {

    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            email: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const result = await createPasswordResetLink(values);
                if (result.ok){
                    showSnackbar('Password reset link sent');
                    setLinkSent(true);
                }
                else{
                    showSnackbar(result.message, "danger");
                }
            } catch (error) {
                showSnackbar(error.message, "danger");
            }
            setLoading(false);
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack direction="column" spacing={1}>
                <Typography level="h2">Forgot Password</Typography>
                <Typography level="body">Enter the email address associated with your account and we'll send you a link to reset your password.</Typography>
                <FormControl error={!!formik.errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input
                        type="text"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                    {formik.errors.email ? (
                        <FormHelperText>{formik.errors.email}</FormHelperText>
                    ) : null}
                </FormControl>
                <Button size="sm" type="submit" loading={loading} disabled={loading}>
                    Submit
                </Button>
                <Box textAlign={"end"}>
                    <RouterLink to="/login">
                        <Link level="body-sm">Remember your password? Login</Link>
                    </RouterLink>
                </Box>
            </Stack>
        </form>
    );
}
