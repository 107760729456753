import React from 'react';

import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import DialogTitle from "@mui/joy/DialogTitle";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";

const PDFModal = ({ open, handleClose, selectedPdf, documentBlobs }) => {
    const pdfUrl = selectedPdf ? documentBlobs[selectedPdf.name]?.[selectedPdf.fileName]?.url : null;

    return (
        <Modal open={open} onClose={handleClose} size="lg">
            <ModalDialog layout="fullscreen">
                <DialogTitle>{selectedPdf ? selectedPdf.fileName : "PDF Document"}</DialogTitle>
                <ModalClose onClick={handleClose} />
                {pdfUrl ? (
                    <iframe
                        src={pdfUrl}
                        style={{ width: '100%', height: '100%' }}
                        title="PDF Viewer"
                    />
                ) : (
                    <Typography level="body-sm">No PDF document selected.</Typography>
                )}
            </ModalDialog>
        </Modal>
    );
};

export default PDFModal;