import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import MenuButton from "@mui/joy/MenuButton";

import ExpandMore from "@mui/icons-material/ExpandMore";

export default function RiskDropdown({risk, onSelect}){

    const colors = {
        low: "success",
        medium: "primary",
        high: "danger"
    }

    const riskText = {
        low: "Low",
        medium: "Medium",
        high: "High"
    }

    return(
        <Dropdown>
            {risk ? 
            <MenuButton size="sm" variant="outlined" color={colors[risk]}
            endDecorator={
                <ExpandMore />
            }>
                {riskText[risk]} Risk
            </MenuButton>
            :
            <MenuButton size="sm" variant="outlined" color="neutral"
                endDecorator={
                    <ExpandMore />
            }>
                Risk Level
            </MenuButton>
            }
            <Menu>
                <MenuItem onClick={() => onSelect("low")}>Low</MenuItem>
                <MenuItem onClick={() => onSelect("medium")}>Medium</MenuItem>
                <MenuItem onClick={() => onSelect("high")}>High</MenuItem>
            </Menu>
        </Dropdown>
    );
}