import Page from '../../layout/Page';
import { useAuth } from '../../firebase/auth';
import { useState } from 'react';
import { Timestamp, collection, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';
import TypeBadge from '../../components/TypeBadge';
import DataTable from '../../components/DataTable';
import TransactionFilterForm from '../../forms/transactions/TransactionFilterForm';
import TransactionDetails from './TransactionDetails';

import Header from '../../layout/Header';

import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';

import Add from '@mui/icons-material/Add';

export default function Transactions() {


    const { authUser } = useAuth();
    const [filters, setFilters] = useState({
        type: [],
        account: [],
        currency: [],
        amount: {
            min: '',
            max: ''
        },
        date: {
            start: '',
            end: ''
        }
    });
    const [filterConstraints, setFilterConstraints] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const headerButtons = () => {
        return (
            <RouterLink to="../transactions/create">
                <Button
                size="sm"
                startDecorator={<Add />}
                >
                    Create New
                </Button>
            </RouterLink>
        );
    }

    const handleFilters = (e) => {
        const q = [];
        if(e.type?.length > 0){
            q.push(where("type", "==", e.type));
        }
        if(e.currency?.length > 0){
            q.push(where("currency", "==", e.currency));
        }
        if(e.account?.length > 0){
            q.push(where("account", "==", e.account));
        }
        if(e.amount?.min && e.amount?.min !== ''){
            q.push(where("amount", ">=", parseInt(e.amount.min)));
        }
        if(e.amount?.max && e.amount?.max !== ''){
            q.push(where("amount", "<=", parseInt(e.amount.max)));
        }
        if(e.date?.start && e.date?.start !== ''){
            q.push(where("date", ">=", Timestamp.fromDate(new Date(e.date.start))));
        }
        if(e.date?.end && e.date?.end !== ''){
            q.push(where("date", "<=", Timestamp.fromDate(new Date(e.date.end))));
        }
        setFilters(e);
        setFilterConstraints(q);
    }
    
    const dataQuery = query(collection(db, 'companies', authUser.company.uid, 'transactions'), orderBy("date", "desc"));
    const searchField = "keywords"
    const columns = ["Date","Customer","Type","Counterparty","Amount","Currency"];
    const pageSize = 10;
    const rowRenderer = (transaction) => {
        return (
            <tr key={transaction.uid} style={{ cursor: 'pointer' }} onClick={()=>setSelectedTransaction(transaction)}>
                <td>{format(transaction.date.toDate(), "dd/MM/yyyy' 'HH:mm")}</td>
                <td>
                    <Typography fontWeight={500}>
                        { transaction.customerType === 'customer' &&
                            transaction.customerDetails.firstName+" "+transaction.customerDetails.lastName
                        }
                        { transaction.customerType === 'business' &&
                            transaction.customerDetails.businessName
                        }
                    </Typography>
                </td>
                <td>
                    <TypeBadge>{transaction.type}</TypeBadge>
                </td>
                <td>
                    {transaction.counterparty?.name}
                </td>
                <td>
                    {transaction.amount}
                </td>
                <td>
                    {transaction.currency}
                </td>
            </tr>
        );
    }

    return (
        <Page>
            <Header title="Transactions" controls={headerButtons()}/>
            <DataTable
                tableTitle="Transactions"
                dataQuery={dataQuery}
                columns={columns}
                rowRenderer={rowRenderer}
                pageSize={pageSize}
                searchField={searchField}
                filterForm={<TransactionFilterForm filters={filters} handleFilters={handleFilters} />}
                filters={filterConstraints}
                setFilters={setFilters}
            />
            <TransactionDetails transaction={selectedTransaction} onClose={()=>setSelectedTransaction(null)} />
        </Page>
    );
};