import TypeBadge from '../../components/TypeBadge';
import BusinessInfoTable from '../../components/BusinessInfoTable';
import CustomerInfoTable from '../../components/CustomerInfoTable';

import Typography from '@mui/joy/Typography';
import DialogTitle from '@mui/joy/DialogTitle';
import ModalClose from '@mui/joy/ModalClose';
import Drawer from '@mui/joy/Drawer';
import Stack from '@mui/joy/Stack';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/joy/Link';
import { format } from 'date-fns';
import { ArrowRight } from '@mui/icons-material';

export default function TransactionDetails({transaction , onClose}) {

    return (
        <Drawer anchor="right" open={transaction} onClose={onClose}>
            { transaction && 
            <>
                <ModalClose />
                <DialogTitle>Transaction Details</DialogTitle>
                <Stack direction="column" p={1.5} spacing={0}>
                    <table>
                        <tr>
                            <td>
                                <Typography level="title-sm">ID</Typography>
                            </td>
                            <td>
                                <Typography level="body-sm">{transaction.uid}</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography level="title-sm">Date</Typography>
                            </td>
                            <td>
                                <Typography level="body-sm">{format(transaction.date.toDate(), "dd/MM/yyyy' 'HH:mm")}</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography level="title-sm">Type</Typography>
                            </td>
                            <td>
                                <Typography level="body-sm"><TypeBadge>{transaction.type}</TypeBadge></Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography level="title-sm">Amount</Typography>
                            </td>
                            <td>
                                <Typography level="body-sm">{transaction.amount} {transaction.currency}</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography level="title-sm">Currency</Typography>
                            </td>
                            <td>
                                <Typography level="body-sm">{transaction.currency}</Typography>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Typography level="title-sm">Account</Typography>
                            </td>
                            <td>
                                <Typography level="body-sm">{transaction.account}</Typography>
                            </td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            { transaction.customerType === 'customer' &&
                            <>
                                <td>
                                    <Typography level="title-lg" mt={2} mb={1}>Customer</Typography>
                                </td>
                                <td style={{textAlign:"end"}}>
                                    <RouterLink to={"/customers/"+transaction.customer}>
                                        <Link level="body-xs" endDecorator={
                                            <ArrowRight />
                                        }>
                                            View Customer
                                        </Link>
                                    </RouterLink>
                                </td>
                            </>
                            }
                            { transaction.customerType === 'business' &&
                            <>
                                <td>
                                    <Typography level="title-lg" mt={2} mb={1}>Business</Typography>
                                </td>
                                <td style={{textAlign:"end"}}>
                                    <RouterLink to={"/businesses/"+transaction.customer}>
                                        <Link level="body-xs" endDecorator={
                                            <ArrowRight />
                                        }>
                                            View Business
                                        </Link>
                                    </RouterLink>
                                </td>
                            </>
                            }
                        </tr>
                    </table>
                    { transaction.customerType === 'business' &&
                        <BusinessInfoTable business={transaction.customerDetails} />
                    }
                    { transaction.customerType === 'customer' &&
                        <CustomerInfoTable customer={transaction.customerDetails} />
                    }
                    { transaction.counterparty && transaction.counterparty.id &&
                        <table>
                            <tr>
                                <td>
                                    <Typography level="title-lg" mt={2} mb={1}>Counterparty</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography level="title-sm">ID Number</Typography>
                                </td>
                                <td>
                                    <Typography level="body-sm">{transaction.counterparty.id}</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography level="title-sm">Full Name</Typography>
                                </td>
                                <td>
                                    <Typography level="body-sm">{transaction.counterparty.name}</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography level="title-sm">Nationality</Typography>
                                </td>
                                <td>
                                    <Typography level="body-sm">{transaction.counterparty.country}</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Typography level="title-sm">Account</Typography>
                                </td>
                                <td>
                                    <Typography level="body-sm">{transaction.counterparty.account}</Typography>
                                </td>
                            </tr>
                        </table>
                    }
                </Stack>
            </>
        }
        </Drawer>
    );
}