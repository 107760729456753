import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Chip from "@mui/joy/Chip";
import Button from "@mui/joy/Button";
import Drawer from "@mui/joy/Drawer";
import DialogTitle from "@mui/joy/DialogTitle";
import ModalClose from "@mui/joy/ModalClose";
import Accordion from "@mui/joy/Accordion";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary from "@mui/joy/AccordionSummary";
import AccordionDetails from "@mui/joy/AccordionDetails";

export default function RejectionModal({show, setShow, selectedTags, setSelectedTags, onSubmit}){
    
    const rejectionTags = {
        "Regulations violations":[
            "Age",
            "Duplicate",
            "Wrong region"
        ],
        "Suspected fraud":[
            "Fake",
            "Blocklist",
            "Selfie mismatch",
            "High risk profile",
            "Forced verification",
            "Printed copy of ID",
            "Deepfake",
            "Liveness with phone",
            "Template"
        ],
        "Selfie issues":[
            "Different people",
            "Bypass attempts"
        ],
        "Compromised persons":[
            "PEP",
            "Sanction lists",
            "Criminal records",
            "Adverse media",
            "Incomplete data"
        ],
        "Different docs":[
            "Different docs"
        ],
        "Spam":[
            "Spam"
        ],
        "KYC":[
            "Not enough data in source",
            "KYC skipped",
            "Data mismatch"
        ]
    }

    const handleHide = () => setShow(false);

    const handleTagToggle = (tag) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags((prevTags) => prevTags.filter((prevTag) => prevTag !== tag));
        } else {
            setSelectedTags((prevTags) => [...prevTags, tag]);
        }
    };

    return(
        <Drawer anchor="right" open={show} onClose={handleHide}>
            <DialogTitle>Select Rejection Tags</DialogTitle>
            <ModalClose onClose={handleHide} />
            <Stack direction="column" spacing={2} p={2}>
                <form>
                    <AccordionGroup transition="0.2s ease">
                    {Object.entries(rejectionTags).map(([category, tags]) => (
                        <Accordion key={category} eventKey={category}>
                            <AccordionSummary>
                                <Typography>
                                    {category+" "}
                                        {
                                            selectedTags.filter(tag => tags.includes(tag)).length > 0 &&
                                            <Chip color="danger">
                                                {selectedTags.filter(tag => tags.includes(tag)).length}
                                            </Chip>
                                        }
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="row" flexWrap="wrap" useFlexGap spacing={0.5}>
                                    {tags.map((tag) => (
                                        <Chip
                                            id={`tag-${tag.toLowerCase()}`}
                                            key={tag}
                                            value={tag}
                                            color="danger"
                                            variant={selectedTags && selectedTags.includes(tag) ? "solid" : "outlined"}
                                            aria-selected={selectedTags && selectedTags.includes(tag)}
                                            onClick={() => handleTagToggle(tag)}
                                            sx={(theme) => ({
                                                [`&[aria-pressed="true"]`]: {
                                                    ...theme.variants.outlinedActive.neutral,
                                                    borderColor: theme.vars.palette.neutral.outlinedHoverBorder,
                                                },
                                            })}
                                        >
                                            {tag}
                                        </Chip>
                                    ))}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    </AccordionGroup>
                </form>
                <Stack direction="column" spacing={1}>
                    <Button size="sm" color="danger" onClick={onSubmit}>
                        Reject Application
                    </Button>
                </Stack>
            </Stack>
        </Drawer>
    );
}