import { useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useAuth } from "../../firebase/auth";
import { db } from "../../firebase/firebase";
import countries from "../../utils/countries";
import { useFormik } from 'formik';

import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Autocomplete from "@mui/joy/Autocomplete";
import Chip from "@mui/joy/Chip";
import Stack from "@mui/joy/Stack";

export default function CustomerFilterForm({ levels, setLevels, filters, handleFilters }) {
    const { authUser } = useAuth();
    const statusOptions = ["pending", "in progress", "rejected", "approved"];
    const riskOptions = ["low", "medium", "high"];
    const countryOptions = countries.map(country => country.countryNameEn);

    useEffect(() => {
        if (!levels) {
            const q = query(collection(db, "companies", authUser.company.uid, "levels"), where("isDeleted", "==", false), where("type", "==", "kyc"));
            getDocs(q).then((snapshot) => {
                let updatedLevels = [];
                snapshot.forEach((doc) => {
                    updatedLevels.push({
                        uid: doc.id,
                        ...doc.data(),
                    });
                });
                setLevels(updatedLevels);
            });
        }
    }, [levels, setLevels]);

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            status: filters.status || [],
            level: filters.level || [],
            risk: filters.risk || [],
            country: filters.country || [],
            date: {
                start: filters.date?.start || '',
                end: filters.date?.end || ''
            }
        },
        onSubmit: (values) => {
            handleFilters(values);
        },
        onReset: () => {
            handleFilters({
                status: [],
                level: [],
                risk: [],
                country: [],
                date: {
                    start: '',
                    end: ''
                }
            });
        }
    });

    return (
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
            <Stack direction="column" spacing={1}>
                <FormControl>
                    <Grid container spacing={2}>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel>Start Date</FormLabel>
                                <Input name="date.start" type="date" value={formik.values.date.start} onChange={formik.handleChange} />
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl>
                                <FormLabel>End Date</FormLabel>
                                <Input name="date.end" type="date" value={formik.values.date.end} onChange={formik.handleChange} />
                            </FormControl>
                        </Grid>
                    </Grid>
                </FormControl>
                <FormControl>
                    <FormLabel>Level</FormLabel>
                    <Autocomplete
                        value={formik.values.level}
                        options={levels ? levels.map(level => level.name) : []}
                        onChange={(event, newValue) => formik.setFieldValue("level", newValue)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="soft" color="primary" {...getTagProps({ index })}>
                                    {option}
                                </Chip>
                            ))
                        }
                        renderInput={(params) => <Input {...params} />}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Country</FormLabel>
                    <Autocomplete
                        value={formik.values.country}
                        options={countryOptions}
                        onChange={(event, newValue) => formik.setFieldValue("country", newValue)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="soft" color="primary" {...getTagProps({ index })}>
                                    {option}
                                </Chip>
                            ))
                        }
                        renderInput={(params) => <Input {...params} />}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Status</FormLabel>
                    <Autocomplete
                        multiple
                        limitTags={2}
                        value={formik.values.status}
                        options={statusOptions}
                        onChange={(event, newValue) => formik.setFieldValue("status", newValue)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="soft" color="primary" {...getTagProps({ index })}>
                                    {option.charAt(0).toUpperCase() + option.slice(1)}
                                </Chip>
                            ))
                        }
                        renderInput={(params) => <Input {...params} />}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Risk</FormLabel>
                    <Autocomplete
                        multiple
                        limitTags={2}
                        value={formik.values.risk}
                        options={riskOptions}
                        onChange={(event, newValue) => formik.setFieldValue("risk", newValue)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="soft" color="primary" {...getTagProps({ index })}>
                                    {option.charAt(0).toUpperCase() + option.slice(1)}
                                </Chip>
                            ))
                        }
                        renderInput={(params) => <Input {...params} />}
                    />
                </FormControl>
                <Stack direction="column" spacing={1}>
                    <Button size="sm" type="submit">Apply Filters</Button>
                    <Button size="sm" type="reset" color="neutral" variant="soft">Clear Filters</Button>
                </Stack>
            </Stack>
        </form>
    );
}
