import Chip from '@mui/joy/Chip';
import { transactionTypes } from '../utils/constants';

export default function TypeBadge({ children }){
    
    const index = transactionTypes.indexOf(children);

    const colors = ["primary", "success", "warning", "neutral", "danger"]
    const color = colors[index % colors.length];

    return (
        <Chip size="sm" color={color} variant="soft">{children}</Chip>
    );
}