import { useState } from "react";
import { Link as RouterLink } from 'react-router-dom';

import LayoutAuth from "../../layout/LayoutAuth";
import ForgotPasswordForm from '../../forms/auth/ForgotPasswordForm';

import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";

export default function ForgotPassword(){

    const [linkSent, setLinkSent] = useState(false);

    return(
        <LayoutAuth>
            {linkSent ? 
            <Stack direction="column" spacing={1}>
                <Typography level="h2">Reset Link Sent</Typography>
                <Typography level="body">Please check your email for the link to reset your password.</Typography>
                <RouterLink to="/login">
                    <Button size="sm" sx={{width:1}}>
                        Return to Login
                    </Button>
                </RouterLink>
            </Stack>
            :
            <ForgotPasswordForm setLinkSent={setLinkSent}/>
            }
        </LayoutAuth>
    );
}