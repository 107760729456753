import Page from '../../layout/Page';
import TransactionCreateForm from "../../forms/transactions/TransactionCreateForm"
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';
import CustomerInfoTable from '../../components/CustomerInfoTable';
import BusinessInfoTable from '../../components/BusinessInfoTable';

export default function TransactionsCreate() {

    const navigate = useNavigate();
    const location = useLocation();
    const [client, setClient] = useState(null);
    const [type, setType] = useState(null);

    useEffect(() => {
        if(location.state && location.state.type === 'customer'){
            setType("customer");
        }else if(location.state && location.state.type === "business"){
            setType("business");
        }
        else{
            navigate("..", { relative: "path" })
        }

        if(location.state && location.state.customer){
            setClient(location.state.customer);
        }else{
            navigate("..", { relative: "path" })
        }
    }, [location]);

    return (
        <Page title="Create Transaction">
            {client && 
                <Stack direction={{xs:"column", md:"row"}} spacing={2}>
                    <Box flexGrow={1}>
                        <Typography level="title-lg" mb={1}>Transaction Details</Typography>
                        <TransactionCreateForm customer={client} type={type} onCreate={()=>navigate("/transactions", { relative: "path" })}/>
                    </Box>
                    <Divider orientation="vertical" />
                    <Box flexGrow={1}>
                        { type && type === "customer" &&
                        <>
                            <Typography level="title-lg" mb={1}>Customer Details</Typography>
                            <Stack direction="column" p={0}>
                                <CustomerInfoTable customer={client} />
                            </Stack>
                        </>
                        }
                        { type && type === "business" &&
                        <>
                            <Typography level="title-lg" mb={1}>Business Details</Typography>
                            <Stack direction="column" p={0}>
                                <BusinessInfoTable business={client} />
                            </Stack>
                        </>
                        }
                    </Box>
                </Stack>
            }
        </Page>
    );
};
