import LayoutAuth from "../../layout/LayoutAuth";
import PasswordResetForm from '../../forms/auth/PasswordResetForm';
import { useParams } from "react-router-dom";

export default function PasswordReset(){

    const { linkId } = useParams();

    return(
        <LayoutAuth>
            <PasswordResetForm link={linkId}/>
        </LayoutAuth>
    );
}