import StatusTag from "./StatusTag";

import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";

import Check from "@mui/icons-material/Check";
import Cancel from "@mui/icons-material/Cancel";
import Refresh from "@mui/icons-material/Refresh";

export default function ApplicationCard({title, name, check, status, onChangeStatus, children}){
    return(
        <>
        <Box sx={{ m: 0 }}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Box sx={{py: 0.5}}>
                    <Typography level="title-md">{title} <StatusTag status={status}/></Typography>
                </Box>
                {
                    check &&
                    <Stack direction="row" spacing={1}>
                        <Button size="sm" variant={status === "approved" ? "soft" : "outlined"}
                            startDecorator={<Check />}
                            color="success" value="approved" onClick={()=>onChangeStatus(name, "approved")}
                        >
                        Approve
                        </Button>
                        <Button size="sm" variant={status === "rejected" ? "soft" : "outlined"}
                            startDecorator={<Cancel />}
                            color="danger" value="rejected" onClick={()=>onChangeStatus(name, "rejected")}
                        >
                        Reject
                        </Button>
                        <Button size="sm" variant={status === "resubmission" ? "soft" : "outlined"}
                            startDecorator={<Refresh />}
                            color="neutral" value="resubmission" onClick={()=>onChangeStatus(name, "resubmission")}
                        >
                        Resubmission
                        </Button>
                    </Stack>
                }
            </Stack>
        </Box>
        <Card sx={{marginTop:"10px!important"}} variant="outlined">
            <CardContent>
                {children}
            </CardContent>
        </Card>
        </>
    );
}