import React, { useState, useEffect } from 'react';
import countries from '../../utils/countries';
import { phone } from 'phone';

import Autocomplete from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Grid from '@mui/joy/Grid';
import AutocompleteListbox from '@mui/joy/AutocompleteListbox';

export default function PhoneInput({ name = "phone", setFieldValue, formik }) {
    const [areaCode, setAreaCode] = useState("");
    const [number, setNumber] = useState("");

    useEffect(() => {
        const formattedAreaCode = areaCode.startsWith('+') ? areaCode : `+${areaCode}`;
        const fullNumber = formattedAreaCode + number;

        if (!areaCode && !number) {
            setFieldValue(name, ""); // Clear the field value
        } else if (!areaCode && number) {
            setFieldValue(name, ""); // Clear the field value
            formik.setFieldError(name, "Please select a country code.");
        } else if (areaCode && !number) {
            setFieldValue(name, ""); // Clear the field value
            formik.setFieldError(name, "Please enter a phone number.");
        } else {
            const result = phone(fullNumber, { validateMobilePrefix: false });
            if (result.isValid) {
                setFieldValue(name, result.phoneNumber); // Set the formatted E.164 number
                formik.setFieldError(name, ""); // Clear any existing error
            } else {
                setFieldValue(name, ""); // Clear the field value
                formik.setFieldError(name, "Invalid phone number");
            }
        }
    }, [number, areaCode, setFieldValue, name]);

    const handleAreaCodeChange = (event, newValue) => {
        if (newValue) {
            setAreaCode(newValue.countryCallingCode);
        } else {
            setAreaCode("");
        }
    };

    const handleNumberChange = (event) => {
        setNumber(event.target.value);
    };

    const handleBlur = (event) => {
        switch (event.target.name) {
            case "areaCode":
                if (!event.target.value) {
                    setAreaCode("");
                }
                break;
            case "number":
                if (!event.target.value) {
                    setNumber("");
                }
                break;
            default:
                break;
        }
    };

    // Custom filter function for Autocomplete
    const filterOptions = (options, { inputValue }) => {
        return options.filter(option => {
            const countryName = option.countryNameEn.toLowerCase();
            const countryCode = `+${option.countryCallingCode}`;
            return (
                countryName.includes(inputValue.toLowerCase()) ||
                countryCode.includes(inputValue)
            );
        });
    };

    return (
        <Grid container justifyContent="space-between" spacing={1}>
            <Grid xs>
                <Autocomplete
                    placeholder="Select country"
                    autoHighlight
                    name="areaCode"
                    autoComplete={false}
                    autoSelect
                    options={countries}
                    filterOptions={filterOptions}  // Apply custom filter logic here
                    slotProps={{
                        listbox: {
                            component: AutocompleteListbox,
                            sx: {
                                wordBreak: 'break-word',
                                width: 'auto',        // Set width to auto
                                minWidth: '200px',    // Optional: Set a minimum width for the listbox
                            }
                        }
                    }}
                    getOptionLabel={(option) => `${option.countryNameEn} (+${option.countryCallingCode})`}
                    renderOption={(props, option) => (
                        <AutocompleteOption {...props} key={option.countryNameEn}>
                            <ListItemDecorator sx={{ display: { xs: 'none', md: 'block' } }}>
                                {option.flag}
                            </ListItemDecorator>
                            <ListItemContent sx={{ fontSize: 'sm' }}>
                                {`${option.countryNameEn} (+${option.countryCallingCode})`}
                            </ListItemContent>
                        </AutocompleteOption>
                    )}
                    onChange={handleAreaCodeChange}
                />
            </Grid>
            <Grid xs>
                <Input
                    type="tel"
                    name="number"
                    value={number}
                    onChange={handleNumberChange}
                    placeholder="000-00-00"
                />
            </Grid>
        </Grid>
    );
}
