import Typography from '@mui/joy/Typography';
import Skeleton from '@mui/joy/Skeleton';

export default function TablePlaceholder({columnCount, rowCount=2}) {


    const getRandString = () => {

        let y = "";
        for(let i=0; i<Math.random()*50; i++){
            y+="a";
        }
        return y;
    
    }

    return(
        <>
            {[...Array(rowCount).keys()].map((rowIndex) => (
                    <tr key={rowIndex}>
                        {[...Array(columnCount).keys()].map((colIndex) => (
                            <td key={colIndex}>
                                <Typography level="body-sm">
                                    <Skeleton>
                                        {getRandString()}
                                    </Skeleton>
                                </Typography>
                            </td>
                        ))}
                    </tr>
                ))}
        </>
    );
}