import React, { useContext, useState, useEffect } from "react";
import { onAuthStateChanged, signOut, sendPasswordResetEmail, signInWithCustomToken } from "firebase/auth";
import { auth, db } from "./firebase";
import { doc, onSnapshot } from "firebase/firestore";

const AuthContext = React.createContext();
const CompanyContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function useCompany() {
  return useContext(CompanyContext);
}

export function AuthProvider({ children }) {
  const [authUser, setAuthUser] = useState();
  const [isLoading, setLoading] = useState(true);

  const clear = () => {
    setAuthUser(null);
    setLoading(false);
  };

  function login(token) {
    return signInWithCustomToken(auth, token);
  }

  function logout() {
    return signOut(auth).then(clear);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setLoading(true);
      if (!user) {
        clear();
        return;
      }
      let newUser = {
        uid: user.uid,
        email: user.email,
        phoneNumber: user.phoneNumber,
        displayName: user.displayName,
        emailVerified: user.emailVerified,
      };
      user.getIdTokenResult().then((idTokenResult) => {
        newUser.role = idTokenResult.claims.role;
        if (!idTokenResult.claims.company) {
          clear();
          return;
        }
        newUser.company = {uid: idTokenResult.claims.company};
        setAuthUser(newUser);
        setLoading(false);
      });
    });
    return unsubscribe;
  }, []);

  const value = {
    authUser,
    login,
    logout,
    resetPassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!isLoading && children}
    </AuthContext.Provider>
  );
}

export function CompanyProvider({ children }) {
  const { authUser } = useAuth();
  const [authCompany, setAuthCompany] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!authUser || !authUser.company.uid) {
      setAuthCompany(null);
      setLoading(false);
      return;
    }

    setLoading(true);
    const companyRef = doc(db, "companies", authUser.company.uid);
    const unsubscribeCompany = onSnapshot(companyRef, doc => {
      if (doc.exists()) {
        setAuthCompany(doc.data());
      } else {
        console.error("Company not found");
        setAuthCompany(null);
      }
      setLoading(false);
    }, error => {
      console.error("Error fetching company data: ", error);
      setAuthCompany(null);
      setLoading(false);
    });

    return () => unsubscribeCompany();
  }, [authUser]);

  const value = {
    authCompany,
  };

  return (
    <CompanyContext.Provider value={value}>
      {!isLoading && children}
    </CompanyContext.Provider>
  );
}
