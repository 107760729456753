import React from 'react';
import countries from '../../utils/countries';
import Autocomplete from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import ListItemContent from '@mui/joy/ListItemContent';
import ListItemDecorator from '@mui/joy/ListItemDecorator';

export default function CountryInput({ name, setFieldValue, value, formik }) {
    const handleChange = (event, newValue) => {
        if (newValue) {
            setFieldValue(name, newValue.countryNameEn);
        }
    };

    const handleBlur = (event) => {
        if(!event.target.value){
            setFieldValue(name, '');
        }
    }

    return (
        <Autocomplete
            autoComplete={false}
            placeholder="Select country"
            options={countries}
            getOptionLabel={(option) => option.countryNameEn}
            value={countries.find(country => country.countryNameEn === value) || null}
            onChange={handleChange}
            onBlur={handleBlur}
            renderOption={(props, option) => (
                <AutocompleteOption {...props} key={option.countryNameEn}>
                    <ListItemDecorator sx={{ display: { xs: 'none', md: 'block' } }}>
                        {option.flag}
                    </ListItemDecorator>
                    <ListItemContent sx={{ fontSize: 'sm' }}>
                        {option.countryNameEn}
                    </ListItemContent>
                </AutocompleteOption>
            )}
        />
    );
}
