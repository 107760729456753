import { useCustomer } from "./CustomerProfile";
import { useAuth } from "../../firebase/auth";
import { query, where, collection, orderBy } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { format } from "date-fns";

import DataTable from "../../components/DataTable";
import TypeBadge from "../../components/TypeBadge";

import TransactionCreateForm from "../../forms/transactions/TransactionCreateForm";
import TransactionDetails from "../transactions/TransactionDetails";

import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import Drawer from "@mui/joy/Drawer";
import DialogTitle from "@mui/joy/DialogTitle";
import ModalClose from "@mui/joy/ModalClose";

import Add from "@mui/icons-material/Add";
import { useState, useEffect, useCallback } from "react";

export default function CustomerProfileTransactions() {
    const { authUser } = useAuth();
    const customer = useCustomer();
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const [createTransaction, setCreateTransaction] = useState(false);
    const [refresh, setRefresh] = useState(false);

    const handleCreate = useCallback(() => {
        setCreateTransaction(false);
        setRefresh(prev => !prev);
    }, []);

    useEffect(() => {
    }, [refresh]);

    if (!customer) {
        return null;
    }

    const transactionDataQuery = query(collection(db, 'companies', authUser.company.uid, 'transactions'), where('customer', '==', customer.uid), orderBy('date', 'desc'));
    const columns = ["Date", "Type", "Counterparty", "Amount", "Currency", "Created At", "Created By"];
    const pageSize = 10;
    const rowRenderer = (transaction) => {
        return (
            <tr key={transaction.uid} style={{ cursor: 'pointer' }} onClick={()=>setSelectedTransaction(transaction)}>
                <td>{format(transaction.date.toDate(), "dd/MM/yyyy' 'HH:mm")}</td>
                <td><TypeBadge>{transaction.type}</TypeBadge></td>
                <td>{transaction.counterparty?.name}</td>
                <td>{transaction.amount}</td>
                <td>{transaction.currency}</td>
                <td>{format(transaction.createdAt.toDate(), "dd/MM/yyyy' 'HH:mm")}</td>
                <td>{transaction.createdByName}</td>
            </tr>
        );
    };

    return (
        <Stack direction="column" spacing={2}>
            <Stack direction="row" justifyContent="space-between">
                <Typography level="h4">Transactions</Typography>
                <Button
                    size="sm"
                    variant="solid"
                    color="primary"
                    startDecorator={<Add />}
                    onClick={() => {
                        setCreateTransaction(true);
                    }}>
                    Create Transaction
                </Button>
            </Stack>
            <DataTable key={refresh} dataQuery={transactionDataQuery} columns={columns} rowRenderer={rowRenderer} pageSize={pageSize} />
            <Drawer anchor="right" open={createTransaction} onClose={() => setCreateTransaction(false)}>
                <DialogTitle>Create Transaction</DialogTitle>
                <ModalClose />
                <Stack p={2} spacing={2}>
                {
                    createTransaction &&
                    <TransactionCreateForm customer={customer} type="customer" onCreate={handleCreate} />
                }
                </Stack>
            </Drawer>
            <TransactionDetails transaction={selectedTransaction} onClose={()=>setSelectedTransaction(null)} />
        </Stack>
    );
}
