import Box from "@mui/joy/Box";
import Header from "./Header";

// Base Page component
function Page({ children, fullWidth=false, title=null }) {
  return (
    <Box
        component="main"
        className="MainContent"
        sx={{
            px: { xs: fullWidth?0:2, md: fullWidth?0:6 },
            pt: {
            xs: 'calc(12px + var(--Header-height))',
            sm: 'calc(12px + var(--Header-height))',
            md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 0,
            height: '100svh',
            gap: 1,
            overflowY: 'scroll',
            zIndex: 0,
        }}
    >
        <Box sx={{ flex: 1, width: '100%' }}>
            {title && <Header title={title} />}
            {children}
        </Box>
    </Box>
  );
}

export default Page;
