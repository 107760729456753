import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth, useCompany } from '../../firebase/auth';
import { db } from '../../firebase/firebase';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import CustomModal from '../../components/CustomModal';
import CountryInput from '../../components/form/CountryInput';

import Stack from '@mui/joy/Stack';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Divider from '@mui/joy/Divider';
import { useSnackbar } from '../../utils/SnackbarContext';
import { transactionTypes, transactionCurrencies } from '../../utils/constants';
import { Autocomplete } from '@mui/joy';

const validationSchema = Yup.object().shape({
    date: Yup.date().required('Required'),
    type: Yup.string().required('Required').oneOf(transactionTypes, 'Invalid type'),
    amount: Yup.number().required('Required').positive('Amount must be positive'),
    currency: Yup.string().required('Required'),
    account: Yup.string().required('Required'),
    counterparty: Yup.object().shape({
        name: Yup.string().nullable().when('$type', {
            is: (type) => type === 'Transfer In' || type === 'Transfer Out',
            then: () => Yup.string().required('Counterparty Name is required').nullable(),
            otherwise: () => Yup.string().nullable(),
        }),
        id: Yup.string().nullable().when('$type', {
            is: (type) => type === 'Transfer In' || type === 'Transfer Out',
            then: () => Yup.string().required('Counterparty ID is required').nullable(),
            otherwise: () => Yup.string().nullable(),
        }),
        country: Yup.string().nullable().when('$type', {
            is: (type) => type === 'Transfer In' || type === 'Transfer Out',
            then: () => Yup.string().required('Counterparty Nationality is required').nullable(),
            otherwise: () => Yup.string().nullable(),
        }),
        account: Yup.string().nullable().when('$type', {
            is: (type) => type === 'Transfer In' || type === 'Transfer Out',
            then: () => Yup.string().required('Counterparty Account is required').nullable(),
            otherwise: () => Yup.string().nullable(),
        }),
    }),
});

export default function TransactionCreateForm({ customer, type, onCreate = null }) {
    const { authUser } = useAuth();
    const { authCompany } = useCompany();
    const showSnackbar = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            date: '',
            type: '',
            amount: '',
            currency: 'USD',
            account: '',
            counterparty: {
                name: '',
                id: '',
                country: '',
                account: '',
            },
            customerType: type,
        },
        validationSchema,
        onSubmit: async (values) => {
            setIsLoading(true);
            if (authCompany?.subscription?.limits?.transactions <= 0) {
                setIsLoading(false);
                showSnackbar("You have reached the limit of transactions allowed in your plan", "danger");
                return;
            }

            const transactionObj = {
                date: Timestamp.fromDate(new Date(values.date)),
                type: values.type,
                amount: values.amount,
                currency: values.currency,
                account: values.account,
                customer: customer.uid,
                customerDetails: customer,
                customerType: type,
                createdBy: authUser.uid,
                createdByName: authUser.displayName,
                createdAt: Timestamp.now(),
                counterparty: values.counterparty
            };

            try {
                const newTransactionRef = collection(db, "companies", authUser.company.uid, "transactions");
                await addDoc(newTransactionRef, transactionObj);
                if (onCreate) {
                    onCreate();
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                showSnackbar("Error adding document: " + error, "danger");
            }
        },
    });

    const setNow = () => {
        const now = new Date().toISOString().slice(0, -8);
        formik.setFieldValue('date', now);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack direction="column" spacing={1}>
                <FormControl error={!!formik.errors.date}>
                    <FormLabel>Date</FormLabel>
                    <Input
                        type="datetime-local"
                        name="date"
                        value={formik.values.date}
                        onChange={formik.handleChange}
                        endDecorator={
                            <Button size="sm" variant="soft" onClick={setNow}>Now</Button>
                        }
                    />
                    {formik.errors.date && (
                        <FormHelperText>{formik.errors.date}</FormHelperText>
                    )}
                </FormControl>

                <FormControl error={!!formik.errors.type}>
                    <FormLabel>Type</FormLabel>
                    <Select
                        placeholder="Transaction type"
                        {...formik.getFieldProps('type')}
                        onChange={(e, value) => formik.setFieldValue('type', value)}
                    >
                        {transactionTypes.map((type, index) => (
                            <Option key={index} value={type}>{type}</Option>
                        ))}
                    </Select>
                    {formik.errors.type && (
                        <FormHelperText>{formik.errors.type}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={!!formik.errors.amount}>
                    <FormLabel>Amount</FormLabel>
                    <Input
                        type="number"
                        step="0.01"
                        {...formik.getFieldProps('amount')}
                        endDecorator={
                            <>
                                <Divider orientation="vertical" />
                                <Autocomplete
                                    freeSolo={true}
                                    options={transactionCurrencies}
                                    defaultValue={transactionCurrencies[0]}
                                    value={formik.values.currency}
                                    variant="plain"
                                    slotProps={{
                                        listbox: {
                                            variant: 'outlined',
                                        },
                                    }}
                                    sx={{ mr: -1.5, '&:hover': { bgcolor: 'transparent' } }}
                                    onChange={(e, value) => formik.setFieldValue('currency', value)}
                                    onInputChange={(e, value) => formik.setFieldValue('currency', value)} // This captures the free input
                                />
                            </>
                        }
                    />
                    {formik.errors.amount && (
                        <FormHelperText>{formik.errors.amount}</FormHelperText>
                    )}
                </FormControl>

                <FormControl error={!!formik.errors.account}>
                    <FormLabel>Account</FormLabel>
                    <Input
                        type="text"
                        {...formik.getFieldProps('account')}
                    />
                    {formik.errors.account ? (
                        <FormHelperText error>{formik.errors.account}</FormHelperText>
                    ) : (
                        <FormHelperText>Bank Account, Crypto wallet address etc.</FormHelperText>
                    )}
                </FormControl>

                {(formik.values.type === 'Transfer In' || formik.values.type === 'Transfer Out') && (
                    <>
                        <Typography level="title-lg" pt={2}>Counterparty Details</Typography>
                        <FormControl error={!!formik.errors.counterparty?.name}>
                            <FormLabel>Full Name</FormLabel>
                            <Input
                                type="text"
                                name="counterparty.name"
                                value={formik.values.counterparty.name}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.counterparty?.name && (
                                <FormHelperText>{formik.errors.counterparty.name}</FormHelperText>
                            )}
                        </FormControl>

                        <FormControl error={!!formik.errors.counterparty?.id}>
                            <FormLabel>ID Number</FormLabel>
                            <Input
                                type="text"
                                name="counterparty.id"
                                value={formik.values.counterparty.id}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.counterparty?.id && (
                                <FormHelperText>{formik.errors.counterparty.id}</FormHelperText>
                            )}
                        </FormControl>

                        <FormControl error={!!formik.errors.counterparty?.country} sx={{ flexGrow: 1 }}>
                            <FormLabel>Nationality</FormLabel>
                            <CountryInput
                                name="counterparty.country"
                                value={formik.values.counterparty.country}
                                setFieldValue={formik.setFieldValue}
                                formik={formik}
                            />
                            {formik.errors.counterparty?.country && (
                                <FormHelperText>{formik.errors.counterparty.country}</FormHelperText>
                            )}
                        </FormControl>
                        
                        <FormControl error={!!formik.errors.counterparty?.account}>
                            <FormLabel>Account</FormLabel>
                            <Input
                                type="text"
                                name="counterparty.account"
                                value={formik.values.counterparty.account}
                                onChange={formik.handleChange}
                            />
                            <FormHelperText>Bank Account, Crypto wallet address etc.</FormHelperText>
                            {formik.errors.counterparty?.account && (
                                <FormHelperText>{formik.errors.counterparty.account}</FormHelperText>
                            )}
                        </FormControl>
                    </>
                )}

            </Stack>
            <CustomModal.Loading show={isLoading} title="Creating Transaction" />
            <Button size="sm" type="submit" disabled={isLoading} loading={isLoading} sx={{ width: 1, mt: 2 }}>Submit</Button>
        </form>
    );
}
