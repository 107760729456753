import { useState } from 'react';
import { getRegisterOTP, registerUser } from '../../firebase/register';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import Grid from "@mui/joy/Grid";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";

import PhoneInput from '../../components/form/PhoneInput';
import { useSnackbar } from '../../utils/SnackbarContext';

import PasswordRequirements from '../../components/PasswordRequirements';

const validationSchema = Yup.object({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    companyName: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    phoneNumber: Yup.string().required('Required'),
    password: Yup.string().required('Required')
        .min(8)
        .max(20)
        .matches(/(?=.*[A-Z])/)
        .matches(/(?=.*[a-z])/)
        .matches(/(?=.*[0-9])/),
    passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
    otp: Yup.string().when('otpSent', {
        is: true,
        then: Yup.string().required('Required')
    })
});

export default function RegisterForm() {

    const showSnackbar = useSnackbar();
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            firstName: '',
            lastName: '',
            companyName: '',
            email: '',
            phoneNumber: '',
            password: '',
            passwordConfirm: '',
            otp: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            const { email, password, firstName, lastName, companyName, phoneNumber, otp } = values;
            if (otpSent) {
                const data = { email, password, firstName, lastName, companyName, phoneNumber, otpCode: otp };
                registerUser(data)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        showSnackbar("User registered successfully. Please check your email for verification.", "success");
                        navigate('/login');
                    } else {
                        showSnackbar(data.message, "danger");
                    }
                })
                .catch(error => showSnackbar(error.message, "danger"))
                .finally(() => setLoading(false));
            } else {
                const data = { email, password, firstName, lastName, companyName, phoneNumber };
                getRegisterOTP(data)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setOtpSent(true);
                    } else {
                        showSnackbar(data.message, "danger");
                    }
                })
                .catch(error => showSnackbar(error.message, "danger"))
                .finally(() => setLoading(false));
            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            {!otpSent ? (
                <Stack direction="column" spacing={1}>
                    <Typography level="h2">Register</Typography>
                    <Grid container justifyContent="space-between">
                        <Grid xs={6}>
                            <FormControl error={formik.touched.firstName && !!formik.errors.firstName} sx={{ marginRight: 0.5 }}>
                                <FormLabel>First Name</FormLabel>
                                <Input
                                    type="text"
                                    name="firstName"
                                    onChange={formik.handleChange}
                                    value={formik.values.firstName}
                                />
                                {formik.touched.firstName && formik.errors.firstName ? (
                                    <FormHelperText>{formik.errors.firstName}</FormHelperText>
                                ) : null}
                            </FormControl>
                        </Grid>
                        <Grid xs={6}>
                            <FormControl error={formik.touched.lastName && !!formik.errors.lastName} sx={{ marginLeft: 0.5 }}>
                                <FormLabel>Last Name</FormLabel>
                                <Input
                                    type="text"
                                    name="lastName"
                                    onChange={formik.handleChange}
                                    value={formik.values.lastName}
                                />
                                {formik.touched.lastName && formik.errors.lastName ? (
                                    <FormHelperText>{formik.errors.lastName}</FormHelperText>
                                ) : null}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <FormControl error={formik.touched.companyName && !!formik.errors.companyName}>
                        <FormLabel>Company Name</FormLabel>
                        <Input
                            type="text"
                            name="companyName"
                            onChange={formik.handleChange}
                            value={formik.values.companyName}
                        />
                        {formik.touched.companyName && formik.errors.companyName ? (
                            <FormHelperText>{formik.errors.companyName}</FormHelperText>
                        ) : null}
                    </FormControl>
                    <FormControl error={formik.touched.email && !!formik.errors.email}>
                        <FormLabel>Email</FormLabel>
                        <Input
                            type="email"
                            name="email"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <FormHelperText>{formik.errors.email}</FormHelperText>
                        ) : null}
                    </FormControl>
                    <FormControl error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}>
                        <FormLabel>Phone Number</FormLabel>
                        <PhoneInput
                            name="phoneNumber"
                            setFieldValue={formik.setFieldValue}
                            formik={formik}
                        />
                        {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                            <FormHelperText>{formik.errors.phoneNumber}</FormHelperText>
                        ) : null}
                    </FormControl>
                    <FormControl error={formik.touched.password && !!formik.errors.password}>
                        <FormLabel>Password</FormLabel>
                        <Input
                            type="password"
                            name="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                        />
                        <PasswordRequirements password={formik.values.password} /> {/* Include PasswordRequirements component */}
                    </FormControl>
                    <FormControl error={formik.touched.passwordConfirm && !!formik.errors.passwordConfirm}>
                        <FormLabel>Confirm Password</FormLabel>
                        <Input
                            type="password"
                            name="passwordConfirm"
                            onChange={formik.handleChange}
                            value={formik.values.passwordConfirm}
                        />
                        {formik.touched.passwordConfirm && formik.errors.passwordConfirm ? (
                            <FormHelperText>{formik.errors.passwordConfirm}</FormHelperText>
                        ) : null}
                    </FormControl>
                    <Button size="sm" type="submit" loading={loading} disabled={loading}>
                        Create Account
                    </Button>
                    <Box textAlign={"end"}>
                        <RouterLink to="/login">
                            <Link level="body-sm">Already have an account? Log In</Link>
                        </RouterLink>
                    </Box>
                </Stack>
            ) : (
                <Stack direction="column" spacing={1}>
                    <Typography level="h2">Verify Your Phone</Typography>
                    <Typography level="body">Please verify the OTP code we sent to your phone number</Typography>
                    <FormControl error={formik.touched.otp && !!formik.errors.otp}>
                        <FormLabel>Enter Code</FormLabel>
                        <Input
                            type="text"
                            name="otp"
                            onChange={formik.handleChange}
                            value={formik.values.otp}
                        />
                        {formik.touched.otp && formik.errors.otp ? (
                            <FormHelperText>{formik.errors.otp}</FormHelperText>
                        ) : null}
                    </FormControl>
                    <Button size="sm" type="submit" loading={loading} disabled={loading}>
                        Verify
                    </Button>
                </Stack>
            )}
        </form>
    );
}
