import { useState } from "react";
import Input from "@mui/joy/Input";
import Button from "@mui/joy/Button";

import CloudUpload from "@mui/icons-material/CloudUpload";
import Task from "@mui/icons-material/Task";

export default function FileInput({ name, onChange, formik }) {
  const [fileName, setFileName] = useState("");
  const sizeLimit = 10000000;
  const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "application/pdf"];

  const handleChange = (event) => {
    if(event.target.files.length === 0){
      return;
    }
    const file = event.target.files[0];
    if (file.size > sizeLimit) {
      formik.setFieldError(name, "File size exceeds 10MB");
      return;
    }
    if (!allowedTypes.includes(file.type)) {
      formik.setFieldError(name, "Invalid file type");
      return;
    }
    setFileName(file.name);
    formik.setFieldError(name, false);

    formik.setFieldValue(name, file.name); // Update formik field value
    onChange(event);
  };

  return (
    <>
      <Button
        size="sm"
        component="label"
        sx={{ width: "100%" }}
        role={undefined}
        tabIndex={-1}
        variant="outlined"
        color="neutral"
        startDecorator={
          fileName ? <Task color="primary" /> : <CloudUpload />
        }
      >
        <>
          {fileName ? fileName : "Upload File"}
        </>
        <Input type="file" name={name} onChange={handleChange} sx={{ display: "none" }} />
      </Button>
    </>
  );
}
