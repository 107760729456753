import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListDivider from "@mui/joy/ListDivider";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import StepIndicator from "@mui/joy/StepIndicator";
import Stack from "@mui/joy/Stack";

import { useEffect, useState } from "react";

export default function Activities({activity}){

    const [activities, setActivities] = useState(activity);

    const colors = {
        application: 'primary',
        transaction: 'success',
    }

    function formatTimePassed(activityDate) {
        const date = new Date(activityDate);
        const now = new Date();
        const seconds = Math.floor((now - date) / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 1) {
            return `${days} days`;
        } else if (days === 1) {
            return '1 day';
        } else if (hours > 1) {
            return `${hours} hours`;
        } else if (hours === 1) {
            return '1 hour';
        } else if (minutes > 1) {
            return `${minutes} min`;
        } else if (minutes === 1) {
            return '1 min';
        } else {
            return `${seconds} sec`;
        }
    }

    useEffect(() => {
        const sortedActivities = activity;
        if(sortedActivities && sortedActivities.length > 0){
            sortedActivities.reverse();
            setActivities(sortedActivities);
        }else{
            setActivities([]);
        }
    }, [activity]);


    return(
        <Card variant="outlined" sx={{p:0}}>
            <CardContent>
                <Typography level="title-lg" p={2}>Recent Activity</Typography>
                <List sx={{p:0}}>
                    {activities && activities.length > 0 && activities.map((activity) => 
                    <>
                        <ListDivider />
                        <ListItem key={activity.date}>
                            <ListItemDecorator sx={{mr:-3}}>
                                <StepIndicator variant="solid" color={colors[activity.type]} sx={{height:10, width:10}} />
                            </ListItemDecorator>
                            <Box>
                                <Typography level="body-sm" color="body" fontWeight={400}>
                                    {activity.text}
                                </Typography>
                                <Typography level="body-xs">
                                {   formatTimePassed(activity.date.toDate())}
                                </Typography>
                            </Box>
                        </ListItem>
                    </>
                    )}
                    {activities && activities.length === 0 &&
                        <ListItem>
                            <Typography level="body-xs">
                                No recent activity
                            </Typography>
                        </ListItem>
                    }
                    </List>
            </CardContent>
        </Card>
    );
}