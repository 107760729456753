import Page from '../../layout/Page';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomerCreateForm from '../../forms/customers/CustomerCreateForm';
import LevelSelectForm from '../../forms/levels/LevelSelectForm';
import { useEffect, useState } from 'react';

import Card from "@mui/joy/Card"
import CardContent from "@mui/joy/CardContent"


export default function CustomersCreate() {

    const location = useLocation();
    const navigate = useNavigate();
    const [selectedLevel, setSelectedLevel] = useState(null);

    useEffect(() => {
        if(location.state && location.state.level){
            setSelectedLevel(location.state.level);
        }
    }, [location]);

    const handleLevelSelect = (level) => {
        setSelectedLevel(level);
    }

    const onComplete = () => {
        navigate("/customers", { replace: true });
    }

    return (
        <Page title="Create Customer">
            { selectedLevel ? 
                <CustomerCreateForm level={selectedLevel} onComplete={onComplete}/>
            :
                <Card>
                    <CardContent>
                        <LevelSelectForm type="kyc" handleOnChange={handleLevelSelect}/>
                    </CardContent>
                </Card>
            }
        </Page>
    );
};