import { useState } from "react";
import { useCompany } from "../../firebase/auth";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase/firebase";

import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Grid from '@mui/joy/Grid';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import Link from '@mui/joy/Link';

import ArrowRight from "@mui/icons-material/ArrowRight";
import { useSnackbar } from "../../utils/SnackbarContext";

const BillingInfoCard = ({ title, value, linkText, linkUrl }) => {
    return (
        <Card>
            <CardContent>
                <Typography level="body-xs">{title}</Typography>
                <Typography level="h3">{value}</Typography>
                <Link level="body-xs" sx={{display:"none"}} endDecorator={<ArrowRight />}>{linkText}</Link>
            </CardContent>
        </Card>
    );
}

export default function Billing() {

    const { authCompany } = useCompany();
    const [linkLoading, setLinkLoading] = useState(false);
    const showSnackbar = useSnackbar();

    const navigateToPortal = () => {
        setLinkLoading(true);
        httpsCallable(functions, 'createStripePortal')()
        .then((result) => {
            window.location.href = result.data.url;
            setLinkLoading(false);
        })
        .catch((error) => {
            showSnackbar("An error occurred: " + error, "danger");
            setLinkLoading(false);
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid xs={12}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography level="title-md" mb={0}>Your Plan</Typography>
                    <Button size="sm" onClick={navigateToPortal} loading={linkLoading} disabled={linkLoading}>Manage Subscription</Button>
                </Stack>
            </Grid>
            <Grid xs={12} md={4}>
                <BillingInfoCard title="Current plan" value={authCompany.subscription === "trialing" ? "Free Trial" : authCompany.subscription.product.name} linkText="Upgrade plan" linkUrl="#!" />
            </Grid>
            <Grid xs={12} md={4}>
                <BillingInfoCard
                    title={"Current bill /" + authCompany.subscription.product.interval}
                    value={
                        "$" +
                        authCompany.subscription.product.price
                        .toString()
                        .replace(/(\d+)(\d{2})$/, '$1,$2')
                    }
                    linkText="View billing history"
                    linkUrl="#!"
                />
            </Grid>
            <Grid xs={12} md={4}>
                <BillingInfoCard title="Next payment due" value={new Date(authCompany.subscription.current_period_end * 1000).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} linkText="Update payment method" linkUrl="#!" />
            </Grid>
            <Grid xs={12}>
                <Stack direction="column" spacing={2}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography level="title-md" mb={0}>Recent Payments</Typography>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
}
