import CircularProgress from "@mui/joy/CircularProgress";
import DialogTitle from "@mui/joy/DialogTitle";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";

function CustomModal({open, onClose=null, children}){
    return(
        <Modal open={open} onClose={onClose}>
            <ModalDialog>
                {children}
            </ModalDialog>
        </Modal>
    );
}

function LoadingModal({open, title="Loading"}){
    return(
        <Modal open={open}>
            <ModalDialog>
                {title}
                <CircularProgress />
            </ModalDialog>
        </Modal>
    );
}

function InfoModal({open, title="Info", close, message}){
    return(
        <Modal open={open} onClose={close}>
            <ModalDialog>
                <ModalClose/>
                {title}
                <p>{message}</p>
                <Button size="sm" variant="solid" color="neutral" onClick={close}>Close</Button>
            </ModalDialog>
        </Modal>
    );
}

function ErrorModal({open, close, message}){
    return(
        <Modal open={open} onClose={close}>
            <ModalDialog>
                <ModalClose/>
                Error
                <p>{message}</p>
                <Button size="sm" variant="solid" color="neutral" onClick={close}>Close</Button>
            </ModalDialog>
        </Modal>
    );
}

function DeleteModal({open, onClose, loading, title="Delete", message, handleDelete}){
    return(
        <Modal open={open} onClose={onClose}>
            <ModalDialog>
                <ModalClose/>
                <DialogTitle>
                    {title}
                </DialogTitle>
                <Typography level="body-md">{message}</Typography>
                <Stack direction="column" spacing={1}>
                    <Button size="sm" color="danger" loading={loading} disabled={loading} onClick={handleDelete}>
                        Yes, Delete
                    </Button>            
                    <Button size="sm" variant="soft" loading={loading} disabled={loading} color="neutral" onClick={onClose}>
                        Cancel
                    </Button>
                </Stack>
            </ModalDialog>
        </Modal>
    );
}

CustomModal.Error = ErrorModal;
CustomModal.Info = InfoModal;
CustomModal.Loading = LoadingModal;
CustomModal.Delete = DeleteModal;

export default CustomModal;
