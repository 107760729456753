import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Header from '../../layout/Header';
import Page from '../../layout/Page';
import TabListStyled from '../../components/TabListStyled';

import Account from './Account';
import Billing from './Billing';
import Company from './Company';

import Tabs from '@mui/joy/Tabs';
import Tab from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import AuthControl from '../../components/AuthControl';

export default function Settings() {
    const location = useLocation();
    const navigate = useNavigate();

    // Determine which tab is selected based on the current path
    const getCurrentTab = () => {
        if (location.pathname.includes('/settings/billing')) {
            return 'billing';
        }
        if (location.pathname.includes('/settings/company')) {
            return 'company';
        }
        // Default to 'account' tab
        return 'account';
    };

    const [currentTab, setCurrentTab] = useState(getCurrentTab());

    useEffect(() => {
        // Update tab when URL changes
        setCurrentTab(getCurrentTab());
    }, [location.pathname]);

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
        if (newValue === 'account') {
            navigate('/settings/account');
        } else if (newValue === 'billing') {
            navigate('/settings/billing');
        } else if (newValue === 'company') {
            navigate('/settings/company');
        }
    };

    return (
        <Page>
            <Tabs
                aria-label="Settings Tabs"
                value={currentTab}
                onChange={handleTabChange}
                sx={{ bgcolor: "transparent" }}
            >
                <Header
                    title="Settings"
                    tabs={
                        <TabListStyled>
                            <Tab value="account">Account</Tab>
                            <AuthControl.Admin>
                                <Tab value="company">Company</Tab>
                                <Tab value="billing">Billing</Tab>
                            </AuthControl.Admin>
                        </TabListStyled>
                    }
                />
                <TabPanel keepMounted sx={{ px: 0 }} value="account">
                    <Account />
                </TabPanel>
                <AuthControl.Admin>
                    <TabPanel keepMounted sx={{ px: 0 }} value="company">
                        <Company />
                    </TabPanel>
                    <TabPanel keepMounted sx={{ px: 0 }} value="billing">
                        <Billing />
                    </TabPanel>
                </AuthControl.Admin>
            </Tabs>
        </Page>
    );
}
