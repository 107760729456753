import Sheet from '@mui/joy/Sheet';
import IconButton from '@mui/joy/IconButton';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Logo from '../components/Logo';

import Menu from '@mui/icons-material/Menu';

export default function NavbarMobile() {

    function closeSidebar() {
        if (typeof window !== 'undefined') {
            document.documentElement.style.removeProperty('--SideNavigation-slideIn');
            document.body.style.removeProperty('overflow');
        }
    }
    
    function openSidebar() {
        if (typeof window !== 'undefined') {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
        }
    }

    function toggleSidebar() {
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            const slideIn = window
                .getComputedStyle(document.documentElement)
                .getPropertyValue('--SideNavigation-slideIn');
            if (slideIn) {
                closeSidebar();
            } else {
                openSidebar();
            }
        }
    }

    return (
        <Sheet
            sx={{
                display: { xs: 'flex', md: 'none' },
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'fixed',
                top: 0,
                width: '100vw',
                height: 'var(--Header-height)',
                zIndex: 1,
                p: 2,
                gap: 1,
                borderBottom: '1px solid',
                borderColor: 'divider',
            }}
        >
          <GlobalStyles
            styles={(theme) => ({
                ':root': {
                    '--Header-height': '52px',
                    [theme.breakpoints.up('md')]: {
                    '--Header-height': '0px',
                    },
                },
            })}
          />
            <Logo style={{height:2.2+"rem"}}/>
            <IconButton
                onClick={() => toggleSidebar()}
                variant="outlined"
                color="neutral"
                size="sm"
            >
                <Menu />
            </IconButton>
        </Sheet>
    );
}