import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'photoswipe/dist/photoswipe.css';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
import { AuthProvider, CompanyProvider } from './firebase/auth';
import { SnackbarProvider } from './utils/SnackbarContext';

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        background: {
          surface: "#FFFFFF",
        },
        neutral: {
          outlinedBorder: "#E5E8EC"
        }
      }
    },
  },
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <CssVarsProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <CompanyProvider>
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </CompanyProvider>  
      </AuthProvider>
    </CssVarsProvider>
  </React.StrictMode>
);