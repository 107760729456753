import { useState, useEffect } from "react";

import { useBusiness } from "./BusinessProfile";
import { useAuth } from "../../firebase/auth";
import { db, storage } from "../../firebase/firebase";
import { doc, updateDoc, Timestamp } from "firebase/firestore";
import { ref, getBlob } from "firebase/storage";

import PDFModal from "../../components/PDFModal";
import ApplicationCard from "../../components/ApplicationCard";
import BusinessProfileInfoTable from "../../components/BusinessProfileInfoTable";
import DocumentCard from "../../components/DocumentCard";
import RejectionModal from "../../components/RejectionModal";
import ResubmissionModal from "../../components/ResubmissionModal";

import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import { useSnackbar } from "../../utils/SnackbarContext";

export default function BusinessProfileVerification() {

    const { authUser } = useAuth();
    const showSnackbar = useSnackbar();
    const business = useBusiness();

    const [check, setCheck] = useState(false);
    const [status, setStatus] = useState(business.status);
    const [loading, setLoading] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [tagModalOpen, setTagModalOpen] = useState(false);
    const [resubmissionModalOpen, setResubmissionModalOpen] = useState(false);
    const [resubmissionFields, setResubmissionFields] = useState({});
    const [documentBlobs, setDocumentBlobs] = useState({});
    const initialStatus = {
        fields: business.status.fields,
        documents: business.status.documents,
        status: business.status.status
    };

    const [pdfModalShow, setPdfModalShow] = useState(false);
    const [selectedPdf, setSelectedPdf] = useState(null);

    // Fetch document blobs after documents are loaded
    useEffect(() => {
        if (!business) {
            return;
        }
        // Update business status with latest data
        setStatus(business.status);
        business.documents.forEach((document) => {
            document.files.forEach((file) => {
                if (!file.url) return;

                const storageRef = ref(storage, file.url);
                getBlob(storageRef)
                    .then((blob) => {
                        const url = URL.createObjectURL(blob);

                        if (file.url.endsWith(".pdf")) {
                            setDocumentBlobs((prevBlobs) => ({
                                ...prevBlobs,
                                [document.name]: {
                                    ...prevBlobs[document.name],
                                    [file.name]: { url }
                                }
                            }));
                        } else if ([".png", ".jpg", ".jpeg"].some(ext => file.url.endsWith(ext))) {
                            const img = new Image();
                            img.onload = () => {
                                const dimensions = { width: img.naturalWidth, height: img.naturalHeight };
                                setDocumentBlobs((prevBlobs) => ({
                                    ...prevBlobs,
                                    [document.name]: {
                                        ...prevBlobs[document.name],
                                        [file.name]: { url, ...dimensions }
                                    }
                                }));
                            };
                            img.src = url; // Triggers the image loading
                        }
                    })
                    .catch((error) => {
                        showSnackbar("An error occurred: " + error, "danger");
                    });
            });
        });
    }, [business, showSnackbar]);

    if (!business) {
        return null;
    }

    const handleStartCheck = () => {
        setCheck(true);
    };

    const onChangeStatus = (name, newStatus) => {
        if (name === "fields") {
            setStatus({
                ...status,
                fields: newStatus
            });
        } else {
            setStatus({
                ...status,
                documents: {
                    ...status.documents,
                    [name]: newStatus
                }
            });
        }
    };

    const getNewStatusObject = (status) => {
        const obj = { ...status };
        const pendingCount = Object.values(status.documents).filter((status) => status === 'pending').length + (status.fields === 'pending' ? 1 : 0);
        const rejectedCount = Object.values(status.documents).filter((status) => status === 'rejected').length + (status.fields === 'rejected' ? 1 : 0);
        const resubmissionCount = Object.values(status.documents).filter((status) => status === 'resubmission').length + (status.fields === 'resubmission' ? 1 : 0);

        if (pendingCount === 0 && rejectedCount === 0 && resubmissionCount === 0) {
            obj.status = 'approved';
        } else if (rejectedCount > 0) {
            obj.status = 'rejected';
        } else if (resubmissionCount > 0) {
            obj.status = 'resubmission';
        } else {
            obj.status = 'pending';
        }

        return obj;
    };

    const submitStatus = (updatedStatus) => {
        setLoading(true);

        if (updatedStatus.status === 'rejected' || updatedStatus.status === 'resubmission') {
            updatedStatus.tags = selectedTags;
        } else {
            updatedStatus.tags = [];
        }
        const docRef = doc(db, "companies", authUser.company.uid, "businesses", business.uid);
        updateDoc(docRef, {
            status: updatedStatus,
            updatedAt: Timestamp.now()
        })
            .then(() => {
                setStatus(updatedStatus);
                showSnackbar("Verification status updated successfully", "success");
            })
            .catch((error) => {
                showSnackbar("Error updating document: " + error, "error");
            })
            .finally(() => {
                setCheck(false);
                setLoading(false);
            });
    };

    const onSave = () => {
        const newStatusObj = getNewStatusObject(status);
        if (newStatusObj.status === 'rejected') {
            setTagModalOpen(true);
        } else if (newStatusObj.status === 'resubmission') {
            const resubmissionFields = {
                companyInformation: status.fields === 'resubmission',
                documents: Object.keys(status.documents).filter((key) => status.documents[key] === 'resubmission'),
            };
            setResubmissionFields(resubmissionFields);
            setResubmissionModalOpen(true);
        } else {
            submitStatus(newStatusObj);
        }
    };

    const handleRejectWithTags = () => {
        setTagModalOpen(false);
        submitStatus(status);
    };

    const handleCancelCheck = () => {
        setCheck(false);
        setStatus(initialStatus);
    };

    const onResubmissionComplete = (formattedValues) => {
        let updatedStatus = { ...status };
    
        // Update the fields if company information needs resubmission
        if (formattedValues.resubmission.companyInformation) {
            updatedStatus.fields = "resubmission";
        }
    
        // Update each document's status to 'resubmission' based on the resubmission fields
        formattedValues.resubmission.documents.forEach((docName) => {
            if (updatedStatus.documents[docName]) {
                updatedStatus.documents[docName] = "resubmission";
            }
        });
    
        // Recalculate the overall status
        updatedStatus = getNewStatusObject(updatedStatus);
    
        // Submit the updated status
        submitStatus(updatedStatus);
    };

    // Handle opening the PDF modal
    const handleOpenPdfModal = (documentName, fileName) => {
        setSelectedPdf({ name: documentName, fileName: fileName });
        setPdfModalShow(true);
    };

    // Handle closing the PDF modal
    const handleClosePdfModal = () => {
        setSelectedPdf(null);
        setPdfModalShow(false);
    };

    return (
        <Stack direction="column" spacing={3}>
            <Stack
                spacing={1}
                direction="row"
                justifyContent="space-between"
            >
                <Typography level="h4">Business Verification</Typography>
                <Stack direction="row" spacing={1}>
                    {check && (status.status !== 'approved' && status.status !== 'rejected') &&
                        <>
                            <Button size="sm" loading={loading} disabled={loading} color="primary" variant="solid" onClick={onSave}>Finish Check</Button>
                            <Button size="sm" loading={loading} disabled={loading} color="primary" variant="soft" onClick={handleCancelCheck}>Cancel</Button>
                        </>
                    }
                    {!check && (status.status !== 'approved' && status.status !== 'rejected') &&
                        <Button size="sm" color="primary" variant="solid" onClick={handleStartCheck}>Start Check</Button>
                    }
                </Stack>
            </Stack>
            <ApplicationCard title="Company Information" name="fields" check={check} status={status.fields} onChangeStatus={onChangeStatus}>
                <BusinessProfileInfoTable business={business} />
            </ApplicationCard>
            {business?.documents?.map((document) => (
                <ApplicationCard key={document.name} title={document.name} name={document.name} check={check} status={status.documents[document.name]} onChangeStatus={onChangeStatus}>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                        {document?.files?.map((file) => (
                            <DocumentCard key={file.name} document={document} file={file} documentBlobs={documentBlobs} handleOpenPdfModal={handleOpenPdfModal} />
                        ))}
                    </Stack>
                </ApplicationCard>
            ))}
            <PDFModal open={pdfModalShow} handleClose={handleClosePdfModal} selectedPdf={selectedPdf} documentBlobs={documentBlobs} />
            <RejectionModal show={tagModalOpen} setShow={setTagModalOpen} setSelectedTags={setSelectedTags} selectedTags={selectedTags} onSubmit={handleRejectWithTags} />
            <ResubmissionModal
                show={resubmissionModalOpen}
                customer={business}
                level={business.level}
                resubmissionFields={resubmissionFields}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                setShow={setResubmissionModalOpen}
                onComplete={onResubmissionComplete} // Callback for when resubmission is complete
            />
        </Stack>
    );
}
