import LayoutAuth from '../../layout/LayoutAuth';
import RegisterForm from '../../forms/auth/RegisterForm';

export default function Register() {

    return (
        <LayoutAuth>
            <RegisterForm/>
        </LayoutAuth>
    );
}
