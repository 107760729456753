import { addDoc, collection, doc, getDoc, Timestamp } from 'firebase/firestore'; 
import { db } from './firebase';

const COMPANY_COLLECTION = 'companies';

export async function getCompanyByUid(uid) {
  const companyDoc = doc(db, COMPANY_COLLECTION, uid);

  try {
    const companySnapshot = await getDoc(companyDoc);

    if (!companySnapshot.exists()) {
      return null;
    }

    const companyData = companySnapshot.data();
    return companyData;
  } catch (error) {
    console.error('Error fetching company data:', error);
    return null;
  }
}

export async function createCustomerForCompany(companyUid, customer, level) {
  const customersCollection = collection(db, COMPANY_COLLECTION, companyUid, 'customers');

  try {
    customer.createdAt = Timestamp.now();
    customer.updatedAt = Timestamp.now();
    customer.level = level;
    const customerDoc = await addDoc(customersCollection, customer);
    return customerDoc;
  } catch (error) {
    console.error('Error creating customer:', error);
    return null;
  }
}

export async function createBusinessForCompany(companyUid, business, level) {
  const businessesCollection = collection(db, COMPANY_COLLECTION, companyUid, 'businesses');

  try {
    business.createdAt = Timestamp.now();
    business.updatedAt = Timestamp.now();
    business.level = level;
    const businessDoc = await addDoc(businessesCollection, business);
    return businessDoc;
  } catch (error) {
    console.error('Error creating business:', error);
    return null;
  }
}