import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { jsPDF } from "jspdf";
import { format } from "date-fns";

import { useAuth } from "../firebase/auth";
import { db, functions } from "../firebase/firebase";
import { doc, updateDoc } from "firebase/firestore";

import StatusTag from "../components/StatusTag";
import { useBusiness } from "../pages/businesses/BusinessProfile";
import RiskDropdown from "../components/RiskDropdown";

import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";
import Grid from "@mui/joy/Grid";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Avatar from "@mui/joy/Avatar";
import Button from "@mui/joy/Button";

import FileDownload from "@mui/icons-material/FileDownload";
import { useSnackbar } from "../utils/SnackbarContext";
import { Business } from "@mui/icons-material";

export default function BusinessHeader(){
    
    const business = useBusiness();
    const showSnackbar = useSnackbar();
    const { authUser } = useAuth();

    const [risk, setRisk] = useState(business.status.risk);
    const [downloading, setDownloading] = useState(false);

    const onSelectRisk = (risk) => {
        const newRisk = risk;
        const docRef = doc(db, "companies", authUser.company.uid, "businesses", business.uid);
        updateDoc(docRef, {
            "status.risk": newRisk
        })
        .then(() => {
            setRisk(newRisk);
        });
    }

    const exportTransactionsPDF = async () => {
        setDownloading(true);
        try{
            const generate = httpsCallable(functions, 'createBusinessTransactionsReport');
            const htmlContent = await generate({ businessId: business.uid });
            
            const pdf = new jsPDF({
                compress: true,
            });
            pdf.html(htmlContent.data, {
                callback: function (doc) {
                    const pageCount = doc.internal.getNumberOfPages();
                    for(var i = 1; i <= pageCount; i++) {
                        doc.setPage(i);
                        doc.setFontSize(10);
                        doc.text(204, 293, `${i}`);
                    }
                    pdf.save(`Report_${format(new Date(), 'yyyyMMddHHmm')}${business.uid}.pdf`);
                },
                autoPaging: "text",
                margin: [10, 10, 10, 10],
                width: 190,
                windowWidth: 780,
            });
        }catch(error){
            showSnackbar("An error occurred: " + error, "danger");
        }finally{
            setDownloading(false);
        }
    };

    
    return(
        <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>Business Profile</Typography>
                <Stack direction="row" spacing={1}>
                    <RiskDropdown risk={risk} onSelect={onSelectRisk}/>
                    <Button color="neutral" variant="outlined" size="sm" 
                    startDecorator={<FileDownload />}
                    onClick={exportTransactionsPDF}
                    loading={downloading}
                    disabled={downloading}
                    sx={{display: "none"}}
                    >
                        Export
                    </Button>
                </Stack>
            </Stack>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid xs={12} md={12}>
                    <Stack direction="row" alignItems="center" mb={1}>
                        <Avatar size="lg">
                            <Business />
                        </Avatar>
                        <Box p={1}>
                            <Typography level="h3">
                                {business.businessName}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack direction={{xs:"column", md:"row"}} justifyContent="space-between">
                        <Stack direction="row" spacing={0.5} flexWrap="wrap" useFlexGap mb={1}>
                        <StatusTag soft={false} status={business.status.status} />
                        { business.status.tags && business.status.tags.length >0 &&
                                business.status.tags.map((tag) => (
                                    <Chip key={tag} size="sm" variant="soft" color={business.status.status === "rejected" ? "danger" : "neutral"}>
                                        {tag}
                                    </Chip>
                                ))
                            }
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
};