import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from '../../../components/form/PhoneInput';
import CountryInput from '../../../components/form/CountryInput';

import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Typography from "@mui/joy/Typography";

const validationSchema = Yup.object({
    idNumber: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    nationality: Yup.string().required('Required'),
    dateofbirth: Yup.date().required('Required'),
    phone: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
});

export default function LinkKYCPersonalInformationForm({ useLinkKYC }) {
    const { handlePersonalInformation, isLoading } = useLinkKYC();

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            idNumber: '',
            firstName: '',
            lastName: '',
            nationality: '',
            dateofbirth: '',
            phone: '',
            email: '',
        },
        validationSchema,
        onSubmit: (values) => {
            handlePersonalInformation(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography level="h3" mb={2}>Enter your personal information</Typography>
            <Stack direction="column" spacing={2}>
                <FormControl error={formik.touched.idNumber && !!formik.errors.idNumber}>
                    <FormLabel>ID Number</FormLabel>
                    <Input
                        required
                        type="text"
                        name="idNumber"
                        value={formik.values.idNumber}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.idNumber && formik.errors.idNumber && (
                        <FormHelperText>{formik.errors.idNumber}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={formik.touched.firstName && !!formik.errors.firstName}>
                    <FormLabel>First Name</FormLabel>
                    <Input
                        required
                        type="text"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.firstName && formik.errors.firstName && (
                        <FormHelperText>{formik.errors.firstName}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={formik.touched.lastName && !!formik.errors.lastName}>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                        required
                        type="text"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.lastName && formik.errors.lastName && (
                        <FormHelperText>{formik.errors.lastName}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={formik.touched.nationality && !!formik.errors.nationality}>
                    <FormLabel>Nationality</FormLabel>
                    <CountryInput
                        name="nationality"
                        value={formik.values.nationality}
                        setFieldValue={formik.setFieldValue}
                        formik={formik}
                    />
                    {formik.touched.nationality && formik.errors.nationality && (
                        <FormHelperText>{formik.errors.nationality}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={formik.touched.dateofbirth && !!formik.errors.dateofbirth}>
                    <FormLabel>Date Of Birth</FormLabel>
                    <Input
                        required
                        type="date"
                        name="dateofbirth"
                        value={formik.values.dateofbirth}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.dateofbirth && formik.errors.dateofbirth && (
                        <FormHelperText>{formik.errors.dateofbirth}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={formik.touched.phone && !!formik.errors.phone}>
                    <FormLabel>Phone Number</FormLabel>
                    <PhoneInput
                        name="phone"
                        value={formik.values.phone}
                        setFieldValue={formik.setFieldValue}
                        formik={formik}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                        <FormHelperText>{formik.errors.phone}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={formik.touched.email && !!formik.errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input
                        required
                        type="text"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.email && formik.errors.email && (
                        <FormHelperText>{formik.errors.email}</FormHelperText>
                    )}
                </FormControl>
                <Button size="sm" type="submit" disabled={isLoading} loading={isLoading}>Next</Button>
            </Stack>
        </form>
    );
}
