import { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from './../../firebase/firebase';

import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalOverflow from '@mui/joy/ModalOverflow';
import DialogTitle from '@mui/joy/DialogTitle';
import { useSnackbar } from '../../utils/SnackbarContext';

export default function SubscriptionInactive() {

    const [linkLoading, setLinkLoading] = useState(false);
    const showSnackbar = useSnackbar();

    const navigateToPortal = () => {
        setLinkLoading(true);
        httpsCallable(functions, 'createStripePortal')()
        .then((result) => {
            window.location.href = result.data.url;
        })
        .catch((error) => {
            showSnackbar("An error occurred: " + error, "danger");
            setLinkLoading(false);
        });
    }

    return (
        <Modal open={true}>
            <ModalOverflow>
            <ModalDialog>
                <DialogTitle>Your subscription is inactive</DialogTitle>
                <Typography level="body-sm" mb={2}>
                    Your subscription is currently inactive. It might be due to a cancelation or a failed payment. Please reactivate your subscription or update your payment method to continue using TrueKYC platform.
                </Typography>
                <Stack direction="column" spacing={1}>
                    <Button size="sm" variant="solid" color="primary" onClick={navigateToPortal} loading={linkLoading} disabled={linkLoading}>Manage Subscription</Button>
                    <Button size="sm" variant="soft" color="neutral"
                    onClick={()=>{window.location.href = '/logout'}}>Logout</Button>
                </Stack>
            </ModalDialog>
            </ModalOverflow>
        </Modal>
  );
}