import { useCompany } from "../firebase/auth";
import { Navigate, Outlet } from "react-router-dom";

function NoSubRoute() {
    const { authCompany } = useCompany();

    if (authCompany.subscription.status === 'active' || authCompany.subscription.status === 'trialing') {
        return <Navigate to="/" />
    }
    else{
        return <Outlet/>
    }
}

export default NoSubRoute;
