import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";

export default function Header({title=null, customTitleContent=null, controls=null, tabs=null}){
    return(
        <Box
            sx={{
            top: { sm: -100, md: -110 },
            bgcolor: 'background.body',
            zIndex: 9995,
            }}
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        mb: 1,
                        gap: 1,
                        flexDirection: { xs: 'row' },
                        alignItems: { xs: 'center' },
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                    }}
                >
                    {   title ?
                            <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>{title}</Typography>
                        :
                        customTitleContent ?
                        <Box width={1}>
                            {customTitleContent}
                        </Box>
                        :
                        null
                    }
                    { controls &&
                        <Box>
                            {controls}
                        </Box>
                    }
                </Box>
            </Box>
            { tabs ? tabs : null }
        </Box>
    );
}