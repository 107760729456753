import Chip from '@mui/joy/Chip';

export default function RiskTag({risk}){

    const colors = {
        low: "success",
        medium: "warning",
        high: "danger"
    }

    const riskText = {
        low: "Low",
        medium: "Medium",
        high: "High"
    }

    return(
        <>
            {risk ? 
            <Chip size="sm" variant="soft" color={colors[risk]}>
                {riskText[risk]}
            </Chip>
            :
            null   
            }
        </>
    );
}