import { useAuth } from "../firebase/auth";
import { Navigate, Outlet } from "react-router-dom";

function PrivateRoute() {
    const { authUser } = useAuth();
  
    return (
      (!authUser) ?
      <Navigate to='/login' />
      :
      <Outlet />
    );
  
}

function AdminRoute() {
    const { authUser } = useAuth();
  
    return (
      (!authUser || authUser.role !== "admin") ?
      <Navigate to='/' />
      :
      <Outlet />
    );
  
}

function AdminOrOfficerRoute() {
    const { authUser } = useAuth();
  
    return (
      (!authUser || (authUser.role !== "admin" && authUser.role !== "officer")) ?
      <Navigate to='/' />
      :
      <Outlet />
    );

}

PrivateRoute.Admin = AdminRoute;
PrivateRoute.AdminOrOfficer = AdminOrOfficerRoute;

export default PrivateRoute;
