import FooterAuth from "./FooterAuth";
import Logo from '../components/Logo';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Chip from '@mui/joy/Chip';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import AspectRatio from '@mui/joy/AspectRatio';

const LayoutAuth = ({children}) =>{
    return (
        <Box sx={{ height: '100svh' }}>
            <Grid container>
                <Grid xs={12} lg={5}>
                    <Stack direction="column" justifyContent="space-between" spacing={2} sx={{ minHeight: '100svh' }} p={3}>
                        <Box sx={{textAlign: {xs: "center", lg:"start"}}}>
                            <Logo style={{height:2.2+"rem"}}/>
                        </Box>
                        <Grid container justifyContent="center" flexGrow={{xs:1, sm:0}}>
                            <Grid xs={12} sm={8} md={6} lg={9}>
                                {children}
                            </Grid>
                        </Grid>
                        <FooterAuth />
                    </Stack>
                </Grid>
                <Grid xs={0} lg={7} display={{xs: "none", lg: "block"}}>
                    <Sheet variant="solid" color="primary" invertedColors sx={{height:1}}>
                        <Stack textAlign="center" direction="column" spacing={1} justifyContent="center" px={8} sx={{height:1}}>
                            <Box>
                                <Chip>
                                    Identity Verification Made Simple
                                </Chip>
                            </Box>
                            <Typography fontSize="lg" fontWeight="lg">
                            </Typography>
                            <Typography
                            level="h1"
                            fontWeight="xl"
                            lineHeight={1.1}
                            fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
                            >
                                Streamline your KYC Compliance Journey
                            </Typography>
                            <Typography fontSize="lg">
                                The simplest SaaS solution for your business's compliance needs with seamless customer onboarding and comprehensive transaction monitoring
                            </Typography>
                            <AspectRatio objectFit="contain" variant="plain">
                                <img src="/img/banner-1.png" alt="Auth Screens" />
                            </AspectRatio>
                        </Stack>
                    </Sheet>
                </Grid>
            </Grid>
        </Box>
    )
}

export default LayoutAuth;