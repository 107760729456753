import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import VerifiedTag from "./VerifiedTag";

export default function BusinessProfileInfoTable({ business }) {

    function Title({ children }){
        return(
            <Typography level="title-sm">{ children }</Typography>
        );
    }

    function Value({ children }){
        return(
            <Typography level="body-sm">{ children }</Typography>
        );
    }

    return (
        <Grid container spacing={1}>
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Title>Company Name</Title>
                <Value>{business.businessName}</Value>
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Title>Country of Incorporation</Title>
                <Value>{business.country}</Value>
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Title>Tax Number</Title>
                <Value>{business.taxNumber}</Value>
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Title>Incorporation no</Title>
                <Value>{business.incorporationNumber}</Value>
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Title>Registered Address</Title>
                <Value>{business.address}</Value>
            </Grid>
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Title>Email
                    {business.level.verifications.includes("email") && <VerifiedTag verified={business.emailVerified} />}
                </Title>
                <Value>{business.email}</Value>
            </Grid>
            {
                business.fields && business.fields.map((field) => (
                    <Grid xs={12} sm={6} md={6} lg={3} key={field.name}>
                        <Title>{field.name}</Title>
                        <Value>{field.value}</Value>
                    </Grid>
                )) 
            }
        </Grid>
    );
}
