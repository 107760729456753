import AuthControl from './AuthControl';
import Typography from '@mui/joy/Typography';

export default function BusinessInfoTable({ business }) {
    return(
        <table>
            <tr>
                <td>
                    <Typography level="title-sm">Company Name</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{business.businessName}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography level="title-sm">Country of Incorporation</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{business.country}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography level="title-sm">Tax Number</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{business.taxNumber}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography level="title-sm">Incorporation no</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{business.incorporationNumber}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography level="title-sm">Registered Address</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{business.address}</Typography>
                </td>
            </tr>
            <tr>
                <td>
                    <Typography level="title-sm">Email</Typography>
                </td>
                <td>
                    <Typography level="body-sm">{business.email}</Typography>
                </td>
            </tr>
            <AuthControl.AdminOrOfficer>
                {
                    business.fields && business.fields.map((field) => (
                        <tr key={field.key}>
                            <td>
                                <Typography level="title-sm">{field.name}</Typography>
                            </td>
                            <td>
                                <Typography level="body-sm">{field.value}</Typography>
                            </td>
                        </tr>
                    )) 
                }
            </AuthControl.AdminOrOfficer>
        </table>
    );
}