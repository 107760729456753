import { Navigate, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import Layout from '../layout/Layout';
import Home from './home/Home';
import Customers from './customers/Customers';
import CustomersCreate from './customers/CustomersCreate';
import Levels from './levels/Levels';
import LevelsEdit from './levels/LevelsEdit';
import LevelsCreate from './levels/LevelsCreate';
import { CustomerProfile } from './customers/CustomerProfile';
import { BusinessProfile } from './businesses/BusinessProfile.js';
import Transactions from './transactions/Transactions';
import TransactionsCreate from './transactions/TransactionsCreate.js';
import TransactionsCreateCustomerSelect from './transactions/TransactionsCreateCustomerSelect.js';
import Settings from './settings/Settings';
import Members from './members/Members';
import MembersCreate from './members/MembersCreate.js';
import PrivateRoute from '../utils/PrivateRoute.js';
import SubRoute from '../utils/SubRoute';
import NoSubRoute from '../utils/NoSubRoute';
import Subscription from './subscription/Subscription';
import Businesses from './businesses/Businesses.js';

export default function Dashboard() {

    window.addEventListener('resize', function() {
        if (window.innerWidth < 992 && document.body.classList.contains('sidenav-toggled')) {
            document.body.classList.remove('sidenav-toggled');
        }
    });

    useEffect(() => {
        document.body.classList.add('nav-fixed');
    }, []);

    return (
        <Layout>
            <Routes>
                <Route element={<SubRoute />}>
                    <Route element={<PrivateRoute.AdminOrOfficer />}>
                        <Route path="" element={<Home />} />
                    </Route>
                    <Route element={<PrivateRoute.AdminOrOfficer />}>
                        <Route path="customers" element={<Customers />} />
                        <Route path="customers/:customerId" element={<CustomerProfile />} />
                        <Route path="businesses" element={<Businesses />} />
                        <Route path="businesses/:businessId" element={<BusinessProfile />} />
                        <Route path="levels" element={<Levels />} />
                        <Route path="levels/create/kyc" element={<LevelsCreate type="kyc" />} />
                        <Route path="levels/create/kyb" element={<LevelsCreate type="kyb" />} />
                        <Route path="levels/edit/:levelId" element={<LevelsEdit />} />
                        <Route path="transactions" element={<Transactions />} />
                    </Route>
                    <Route element={<PrivateRoute.Admin />}>
                        <Route path="members/" element={<Members />} />
                        <Route path="members/create" element={<MembersCreate />} />
                    </Route>
                    <Route path="customers/create" element={<CustomersCreate />} />
                    <Route path="transactions/create" element={<TransactionsCreateCustomerSelect />} />
                    <Route path="transactions/create/:type" element={<TransactionsCreateCustomerSelect />} />
                    <Route path="transactions/create/:type/:customerId" element={<TransactionsCreate />} />
                    <Route path="settings/*" element={<Settings />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Route>
                <Route element={<NoSubRoute />}>
                    <Route path="subscribe" element={<Subscription />} />
                </Route>
            </Routes>
        </Layout>
    );
}