import { useEffect, useState } from 'react';

import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function PasswordRequirements({ password }){
    const [requirements, setRequirements] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
    });

    useEffect(() => {
        setRequirements({
            length: password.length >= 8 && password.length <= 20,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            number: /[0-9]/.test(password),
        });
    }, [password]);

    return (
        <Stack direction="column" spacing={0} mt={1}>
            <Typography level="body-xs" color={password ? requirements.length ? "success" : "danger" : "neutral"}>
                { password && requirements.length ? <CheckIcon fontSize="xs" /> : <CloseIcon fontSize="xs" /> }
                8-20 characters
            </Typography>
            <Typography level="body-xs" color={password ? requirements.uppercase ? "success" : "danger" : "neutral"}>
                { password && requirements.uppercase && requirements.lowercase ? <CheckIcon fontSize="xs" /> : <CloseIcon fontSize="xs" /> }
                At least one uppercase and one lowercase letter
            </Typography>
            <Typography level="body-xs" color={password ? requirements.number ? "success" : "danger" : "neutral"}>
                { password && requirements.number ? <CheckIcon fontSize="xs" /> : <CloseIcon fontSize="xs" /> }
                At least one number
            </Typography>
        </Stack>
    );
};
