import Page from '../../layout/Page';
import LevelKYCForm from '../../forms/levels/LevelKYCForm';
import LevelKYBForm from '../../forms/levels/LevelKYBForm';
import { useParams } from 'react-router-dom';

import { doc, getDoc } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { useEffect, useState } from 'react';
import { useAuth } from '../../firebase/auth';
import { useSnackbar } from '../../utils/SnackbarContext';

export default function LevelsEdit() {

    const { authUser } = useAuth();
    const { levelId } = useParams();
    const showSnackbar = useSnackbar();
    
    const [level, setLevel] = useState(null);

    useEffect(() => {
        const docRef = doc(db, "companies", authUser.company.uid, "levels", levelId);
        getDoc(docRef)
        .then((doc) => {
            if (doc.exists()) {
                setLevel({
                    id: doc.id,
                    ...doc.data()
                });
            }
        })
        .catch((error) => {
            showSnackbar("An error occurred: " + error, "danger");
        });
    }, [authUser, levelId]);

    return (
        <>
        { level && level.type === "kyc" &&
            <Page title="Edit Level">
                {level && <LevelKYCForm level={level} />}
            </Page>
        }
        { level && level.type === "kyb" &&
            <Page title="Edit Level">
                {level && <LevelKYBForm level={level} />}
            </Page>
        }
        </>
    );
}
