import Page from '../../layout/Page';
import LevelKYCForm from '../../forms/levels/LevelKYCForm';
import LevelKYBForm from '../../forms/levels/LevelKYBForm';

export default function LevelsCreate({type}) {

    return (
        <>
        { type === "kyc" &&
            <Page title={"Create KYC Level"}>
                <LevelKYCForm/>
            </Page>
        }
        { type === "kyb" &&
            <Page title={"Create KYB Level"}>
                <LevelKYBForm/>
            </Page>
        }
        </>
    );
}
