import React from 'react';
import QRCode from 'react-qr-code';

import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";

import CopyAll from '@mui/icons-material/CopyAll';
import { useSnackbar } from '../utils/SnackbarContext';

function QRLink({ link, onClose }) {

    const showSnackbar = useSnackbar();

    const handleCopyLink = () => {
        try {
            navigator.clipboard.writeText(link);
            showSnackbar('Link copied to clipboard');
        } catch (error) {
            showSnackbar("Failed to copy: " + error, "danger");

        }
    };

    const shareObject = {
        title: 'TrueKYC - Remote KYC Verification Link',
        text: 'Click this link to start your remote identity verification session.',
        url: link
    };

    return (
        <>
            <Stack direction="row" justifyContent="center" p={3}>
                <QRCode level={"L"} value={link} size={200} />
            </Stack>
            <Input type="text" value={link} readOnly endDecorator={
                <Button
                    size="sm" 
                    onClick={handleCopyLink}
                    endDecorator={
                        <CopyAll />
                    }
                >
                Copy
                </Button>
            } />
            <Button size="sm" onClick={() => navigator.share(shareObject)}>
                Share
            </Button>
            <Button size="sm" variant="soft" color="neutral" onClick={onClose}>
                Close
            </Button>
        </>
    );
}

export default QRLink;
