import { functionsURL } from './firebase';

export const sendVerificationSMS = async (phone=null, linkId=null) => {
    if (phone === null && linkId === null) {
        return Promise.reject("Phone or linkId is required")
    }

    let body = {};

    if (phone !== null) {
        body = {
            "phone": phone.toString()
        }
    }else {
        body = {
            "linkId": linkId.toString()
        }
    }

    return fetch(functionsURL+'/sendVerificationSMS', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    .then(response => {
        return response.json()
    })
}

export const verifySMSCode = async  (phone, code) => {
    const body = {
        "phone": phone.toString(),
        "code": code.toString()
    }
    return fetch(functionsURL+'/verifySMSCode', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    .then(response => response.json())
}

export const sendVerificationEmail = async (email=null, linkId=null) => {

    if (email === null && linkId === null) {
        return Promise.reject("Email or linkId is required")
    }

    let body = {};

    if (email !== null) {
        body = {
            "email": email.toString()
        }
    }else {
        body = {
            "linkId": linkId.toString()
        }
    }

    return fetch(functionsURL+'/sendVerificationEmail', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    .then(response => {
        return response.json()
    })
}

export const verifyEmailCode = async  (email, code) => {
    const body = {
        "email": email.toString(),
        "code": code.toString()
    }
    return fetch(functionsURL+'/verifyEmailCode', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    .then(response => response.json())
}

export const verifyLinkSMS = async  (linkId, code, type=null) => {
    const body = {
        "linkId": linkId.toString(),
        "code": code.toString()
    }
    if (type !== null) {
        body.type = type
    }
    return fetch(functionsURL+'/verifyLinkSMS', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    .then(response => response.json())
}

export const verifyLinkEmail = async  (linkId, code, type) => {
    const body = {
        "linkId": linkId.toString(),
        "code": code.toString()
    }
    if (type !== null) {
        body.type = type
    }
    return fetch(functionsURL+'/verifyLinkEmail', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    .then(response => response.json())
}