import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCompany } from '../../firebase/auth';

import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';

export default function Company() {
    const { authCompany } = useCompany();

    // Define Yup validation schema
    const validationSchema = Yup.object({
        companyName: Yup.string()
            .required('Company Name is required')
            .min(2, 'Company Name must be at least 2 characters'),
    });

    // Initialize Formik with Yup validation
    const formik = useFormik({
        initialValues: {
            companyName: authCompany.name || '',
        },
        validationSchema: validationSchema,  // Add Yup validation schema here
        onSubmit: async (values) => {
            
        },

    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack direction="column" spacing={2}>
                <Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography level="title-md">Company Information</Typography>
                    </Box>
                    <Stack direction="column" spacing={1}>
                        <FormControl error={!!formik.errors.companyName}>
                            <FormLabel>Name</FormLabel>
                            <Input
                                type="text"
                                name="companyName"
                                value={formik.values.companyName}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.companyName &&
                                <FormHelperText>{formik.errors.companyName}</FormHelperText>
                            }
                        </FormControl>
                    </Stack>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Button size="sm" type="submit">Save</Button>
                </Box>
            </Stack>
        </form>
    );
}
